// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

(() => {
    const SparkMD5 = require('spark-md5');

    const FormData = require('form-data');
    const { ServerError } = require('./exceptions');
    const store = require('store');
    const config = require('./config');
    const DownloadWorker = require('./download.worker');
    const baseUrl = window.location.origin;

    const fs = require('fs');
    const { createFFmpeg, fetchFile } = require('@ffmpeg/ffmpeg');
    const { v4: uuidv4 } = require('uuid');

    function waitFor(frequencyHz, predicate) {
        return new Promise((resolve, reject) => {
            if (typeof predicate !== 'function') {
                reject(new Error(`Predicate must be a function, got ${typeof predicate}`));
            }

            const internalWait = () => {
                let result = false;
                try {
                    result = predicate();
                } catch (error) {
                    reject(error);
                }

                if (result) {
                    resolve();
                } else {
                    setTimeout(internalWait, 1000 / frequencyHz);
                }
            };

            setTimeout(internalWait);
        });
    }

    function generateError(errorData) {
        if (errorData.response) {
            const message = `${errorData.message}. ${JSON.stringify(errorData.response.data) || ''}.`;
            return new ServerError(message, errorData.response.status);
        }

        // Server is unavailable (no any response)
        const message = `${errorData.message}.`; // usually is "Error Network"
        return new ServerError(message, 0);
    }

    class WorkerWrappedAxios {
        constructor() {
            const worker = new DownloadWorker();
            const requests = {};
            let requestId = 0;

            worker.onmessage = (e) => {
                if (e.data.id in requests) {
                    if (e.data.isSuccess) {
                        requests[e.data.id].resolve(e.data.responseData);
                    } else {
                        requests[e.data.id].reject({
                            error: e.data.error,
                            response: {
                                status: e.data.status,
                                data: e.data.responseData,
                            },
                        });
                    }

                    delete requests[e.data.id];
                }
            };

            worker.onerror = (e) => {
                if (e.data.id in requests) {
                    requests[e.data.id].reject(e);
                    delete requests[e.data.id];
                }
            };

            function getRequestId() {
                return requestId++;
            }

            async function get(url, requestConfig) {
                return new Promise((resolve, reject) => {
                    const newRequestId = getRequestId();
                    requests[newRequestId] = {
                        resolve,
                        reject,
                    };
                    worker.postMessage({
                        url,
                        config: requestConfig,
                        id: newRequestId,
                    });
                });
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    get: {
                        value: get,
                        writable: false,
                    },
                }),
            );
        }
    }

    class ServerProxy {
        constructor() {
            const Axios = require('axios');
            Axios.defaults.withCredentials = true;
            Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
            Axios.defaults.xsrfCookieName = 'csrftoken';
            const workerAxios = new WorkerWrappedAxios();

            let ffmpeg = null;

            const getFFMPEG = async () => {
                if (!ffmpeg) {
                    ffmpeg = createFFmpeg({ log: true, corePath: baseUrl + '/static/ffmpeg/js/ffmpeg-core.js' });
                    await ffmpeg.load();
                } else {
                    console.log('Ffmpeg already defined.');
                    console.log('ffmpeg loaded', ffmpeg.isLoaded());
                }
                return ffmpeg;
            }

            let token = store.get('token');
            if (token) {
                Axios.defaults.headers.common.Authorization = `Token ${token}`;
            }

            async function about() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/about`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function share(directory) {
                const { backendAPI } = config;
                directory = encodeURIComponent(directory);

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/share?directory=${directory}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function exception(exceptionObject) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/server/exception`, JSON.stringify(exceptionObject), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function formats() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/annotation/formats`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function userAgreements() {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/restrictions/user-agreements`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function register(username, firstName, lastName, email, password1, password2, confirmations) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username,
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        password1,
                        password2,
                        confirmations,
                    });
                    response = await Axios.post(`${config.backendAPI}/auth/register`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function login(username, password) {
                const authenticationData = [
                    `${encodeURIComponent('username')}=${encodeURIComponent(username)}`,
                    `${encodeURIComponent('password')}=${encodeURIComponent(password)}`,
                ]
                    .join('&')
                    .replace(/%20/g, '+');

                Axios.defaults.headers.common.Authorization = '';
                let authenticationResponse = null;
                try {
                    authenticationResponse = await Axios.post(`${config.backendAPI}/auth/login`, authenticationData, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (authenticationResponse.headers['set-cookie']) {
                    // Browser itself setup cookie and header is none
                    // In NodeJS we need do it manually
                    const cookies = authenticationResponse.headers['set-cookie'].join(';');
                    Axios.defaults.headers.common.Cookie = cookies;
                }

                token = authenticationResponse.data.key;
                store.set('token', token);
                Axios.defaults.headers.common.Authorization = `Token ${token}`;
            }

            async function logout() {
                try {
                    await Axios.post(`${config.backendAPI}/auth/logout`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                store.remove('token');
                Axios.defaults.headers.common.Authorization = '';
            }

            async function changePassword(oldPassword, newPassword1, newPassword2) {
                try {
                    const data = JSON.stringify({
                        old_password: oldPassword,
                        new_password1: newPassword1,
                        new_password2: newPassword2,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/change`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function requestPasswordReset(email) {
                try {
                    const data = JSON.stringify({
                        email,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/reset`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function resetPassword(newPassword1, newPassword2, uid, _token) {
                try {
                    const data = JSON.stringify({
                        new_password1: newPassword1,
                        new_password2: newPassword2,
                        uid,
                        token: _token,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/reset/confirm`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function authorized() {
                try {
                    await module.exports.users.self();
                } catch (serverError) {
                    if (serverError.code === 401) {
                        return false;
                    }

                    throw serverError;
                }

                return true;
            }

            async function serverRequest(url, data) {
                try {
                    return (
                        await Axios({
                            url,
                            ...data,
                        })
                    ).data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function searchProjectNames(search, limit) {
                const { backendAPI, proxy } = config;

                let response = null;
                try {
                    response = await Axios.get(
                        `${backendAPI}/projects?names_only=true&page=1&page_size=${limit}&search=${search}`,
                        {
                            proxy,
                        },
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function getProjects(filter = '') {
                const { backendAPI, proxy } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/projects?page_size=12&${filter}`, {
                        proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function saveProject(id, projectData) {
                const { backendAPI } = config;

                try {
                    await Axios.patch(`${backendAPI}/projects/${id}`, JSON.stringify(projectData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteProject(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/projects/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function createProject(projectSpec) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/projects`, JSON.stringify(projectSpec), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getTasks(filter = '') {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks?page_size=10&${filter}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function saveTask(id, taskData) {
                const { backendAPI } = config;

                console.log("FUNCTION SAVETASK")
                console.log(taskData)

                try {
                    await Axios.patch(`${backendAPI}/tasks/${id}`, JSON.stringify(taskData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteTask(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/tasks/${id}`, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            function exportDataset(instanceType) {
                return async function (id, format, name, saveImages) {
                    const { backendAPI } = config;
                    const baseURL = `${backendAPI}/${instanceType}/${id}/${saveImages ? 'dataset' : 'annotations'}`;
                    let query = `format=${encodeURIComponent(format)}`;
                    if (name) {
                        const filename = name.replace(/\//g, '_');
                        query += `&filename=${encodeURIComponent(filename)}`;
                    }
                    let url = `${baseURL}?${query}`;

                    return new Promise((resolve, reject) => {
                        async function request() {
                            Axios.get(`${url}`, {
                                proxy: config.proxy,
                            })
                                .then((response) => {
                                    if (response.status === 202) {
                                        setTimeout(request, 3000);
                                    } else {
                                        query = `${query}&action=download`;
                                        url = `${baseURL}?${query}`;
                                        resolve(url);
                                    }
                                })
                                .catch((errorData) => {
                                    reject(generateError(errorData));
                                });
                        }

                        setTimeout(request);
                    });
                };
            }

            async function exportTask(id) {
                const { backendAPI } = config;
                const url = `${backendAPI}/tasks/${id}`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.get(`${url}?action=export`, {
                                proxy: config.proxy,
                            });
                            if (response.status === 202) {
                                setTimeout(request, 3000);
                            } else {
                                resolve(`${url}?action=download`);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            async function importTask(file) {
                const { backendAPI } = config;

                let taskData = new FormData();
                taskData.append('task_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.post(`${backendAPI}/tasks?action=import`, taskData, {
                                proxy: config.proxy,
                            });
                            if (response.status === 202) {
                                taskData = new FormData();
                                taskData.append('rq_id', response.data.rq_id);
                                setTimeout(request, 3000);
                            } else {
                                const importedTask = await getTasks(`?id=${response.data.id}`);
                                resolve(importedTask[0]);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }


            function parseFile(file, callback) {
                var fileSize = file.size;
                var chunkSize = 64 * 1024; // bytes
                var offset = 0;
                var self = this; // we need a reference to the current object
                var chunkReaderBlock = null;

                var readEventHandler = function (evt) {
                    if (evt.target.error == null) {
                        offset += evt.target.result.length;
                        callback(evt.target.result, offset, ); // callback for handling read chunk
                    } else {
                        console.log("Read error: " + evt.target.error);
                        return;
                    }
                    if (offset >= fileSize) {
                        console.log("Done reading file");
                        return;
                    }

                    // of to the next chunk
                    chunkReaderBlock(offset, chunkSize, file);
                }

                chunkReaderBlock = function (_offset, length, _file) {
                    const r = new FileReader();
                    const blob = _file.slice(_offset, length + _offset);
                    r.onload = readEventHandler;
                    r.readAsText(blob);

                }

                // now let's start the read with the first block
                chunkReaderBlock(offset, chunkSize, file);
            }

            function msToTime(s) {
                var ms = s % 1000;
                s = (s - ms) / 1000;
                var secs = s % 60;
                s = (s - secs) / 60;
                var mins = s % 60;
                var hrs = (s - mins) / 60;

                return hrs + ':' + mins + ':' + secs + '.' + ms;
            }

            async function getVideoDuration(input_file, input_file_name=null){
                let duration = 0;

                if (input_file.type.includes('image')) {
                    return duration;
                } else {
                    let _ffmpeg = await getFFMPEG();
                    if (!_ffmpeg) {
                        _ffmpeg = createFFmpeg({
                            logger: (message) => {
                                const regex = /Duration: (\d{2})\:(\d{2})\:(\d{2})\.(\d{2})/gm;
                                const matches = regex.exec(String(message.message));
                                if (matches != null) {
                                    const h = Number(matches[1]);
                                    const m = Number(matches[2]);
                                    const s = Number(matches[3]);
                                    const ms = Number(matches[4]);
                                    // duration = h * 3600 * 1000 + m * 60 * 1000 + s * 1000 + ms;
                                    duration = h * 3600 * 1000 + m * 60 * 1000 + s * 1000;
                                    console.log('createFFmpegduration: ', duration); // duration f.e. 300
                                }
                            },
                            log: false,
                            corePath: baseUrl + '/static/ffmpeg/js/ffmpeg-core.js',
                        });
                        await _ffmpeg.load();
                    } else {
                        _ffmpeg.setLogger((message) => {
                            const regex = /Duration: (\d{2})\:(\d{2})\:(\d{2})\.(\d{2})/gm;
                            const matches = regex.exec(String(message.message));
                            if (matches != null) {
                                const h = Number(matches[1]);
                                const m = Number(matches[2]);
                                const s = Number(matches[3]);
                                const ms = Number(matches[4]);
                                // duration = h * 3600 * 1000 + m * 60 * 1000 + s * 1000 + ms;
                                duration = h * 3600 * 1000 + m * 60 * 1000 + s * 1000;
                                console.log('createFFmpegduration: ', duration); // duration f.e. 300
                            }
                        });
                    }

                    // console.log("duration before");
                    // console.log(duration);

                    const fileuuid = uuidv4();
                    if (!input_file_name) {
                        await _ffmpeg.FS('writeFile', fileuuid, await fetchFile(input_file));
                    }
                    await _ffmpeg.run('-i', input_file_name);
                    if (!input_file_name) {
                        await _ffmpeg.FS('unlink', fileuuid);
                    }
                    // reset logger
                    _ffmpeg.setLogger(() => {
                    });
                    return duration;
                }
            }

            function readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.onload = () => {
                        resolve(reader.result);
                    };

                    reader.onerror = reject;

                    reader.readAsDataURL(file);
                })
            }

            async function cropPreviewProcessorList(input_file, settingList) {
                const res = {};
                const _ffmpeg = await getFFMPEG();
                const fileuuid = input_file.uid ? input_file.uid : uuidv4();
                const input_file_name = input_file.name ? fileuuid + input_file.name : fileuuid;
                await _ffmpeg.FS('writeFile', input_file_name, await fetchFile(input_file));
                let duration = null;

                // const progressTimer = setInterval(() => {
                //     _ffmpeg.setProgress(({ratio}) => {
                //         console.log(ratio);
                //         /*
                //          * ratio is a float number between 0 to 1.
                //          */
                //     });
                // }, 100);

                // get video duration
                duration = await getVideoDuration(input_file, input_file_name);

                for await (const cropSetting of settingList) {
                    const {tag, x1rel, y1rel, x2rel, y2rel} = cropSetting;
                    // placeholder from processor specific cropping
                    let out_wrel = x2rel - x1rel;
                    let out_hrel = y2rel - y1rel;
                    try {
                        res[tag] = await cropPreview(input_file, out_wrel, out_hrel, x1rel, y1rel,
                            true, input_file_name, duration);
                    } catch (errorData) {
                        throw generateError(errorData);
                    }
                }
                try {
                    await _ffmpeg.FS('unlink', input_file_name);
                    // _ffmpeg.exit();
                } catch (e) {
                    console.log('This is a bug in the FFMPEG wasm package - exit should not cause this.');
                    //ffmpeg = null;
                }

                for (const [key, value] of Object.entries(res)) {
                    if (value === null) {
                        generateError('Error while processing preview.');
                        return null;
                    }
                }

                // if (progressTimer) {
                //     clearInterval(progressTimer);
                // }
                return res;
            }

            async function cropPreviewProcessor(input_file, x1rel, y1rel, x2rel, y2rel) {
                // placeholder from processor specific cropping
                let out_wrel = x2rel - x1rel;
                let out_hrel = y2rel - y1rel;
                try {
                    let previewUrlFile = await cropPreview(input_file, out_wrel, out_hrel, x1rel, x2rel, true);
                    return previewUrlFile;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function cropPreview(input_file, out_w, out_h, x, y, relcoord=false, input_file_name=null, vid_duration=null) {
                let _ffmpeg = await getFFMPEG();
                const _input_file_name = input_file_name ? input_file_name : input_file.name;
                const _out_height = 128;
                // const is_image = false;//input_file.type.includes('image');
                const is_image = input_file.type.includes('image');

                if ((input_file instanceof File || input_file_name) && out_w >= 0 && out_h >= 0) {
                    // estimate video duration and get middle of the video
                    let duration = ((typeof(vid_duration) == 'number') && (vid_duration < 1)) ? 0 : vid_duration;
                    duration = 0; // duration ? duration : 1000;
                    let timestamp = Math.floor(duration / 2);

                    var saveData = (function () {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        return function (blob, fileName) {
                            const url = window.URL.createObjectURL(blob);
                            console.log(url);
                            a.href = url;
                            a.download = fileName;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        };
                    }());

                    // const ffmpeg = createFFmpeg({log: true});
                    // await ffmpeg.load();
                    const fileuuid = uuidv4();
                    if (!input_file_name) {
                        _ffmpeg.FS('writeFile', _input_file_name, await fetchFile(input_file));
                    }

                    if (out_w == 0 || out_h == 0) {
                        if (!is_image) {
                            await _ffmpeg.run('-i', _input_file_name, '-filter:v',
                                'select=\'eq(n\,0)\', scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'',
                                '-vframes', '1',
                                '-q:v', 10, fileuuid + 'output_%0d.jpg');
                        } else {
                            await _ffmpeg.run('-i', _input_file_name, '-filter:v',
                                'scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'',
                                '-q:v', 10, fileuuid + 'output_%0d.jpg');
                        }
                    } else {
                        if (relcoord){
                            if (!is_image) {
                                await _ffmpeg.run( '-i', _input_file_name, '-filter:v',
                                'select=\'eq(n\,0)\', crop=' + 'iw*' + out_w.toString() + ':' + 'ih*' + out_h.toString() + ':' + 'iw*' + x.toString() + ':' + 'ih*' + y.toString() + ',scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'', '-vframes', '1', '-q:v', 10, fileuuid + 'output_%0d.jpg');
                            } else {
                                await _ffmpeg.run('-i', _input_file_name, '-filter:v',
                                    'crop=' + 'iw*' + out_w.toString() + ':' + 'ih*' + out_h.toString() + ':' + 'iw*' + x.toString() + ':' + 'ih*' + y.toString() + ',scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'', '-q:v', 10, fileuuid + 'output_%0d.jpg');
                            }
                        } else {
                            if (!is_image) {
                                await _ffmpeg.run('-i', _input_file_name, '-filter:v',
                                'select=\'eq(n\,0)\', crop=' + out_w.toString() + ':' + out_h.toString() + ':' + x.toString() + ':' + y.toString() + ',scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'', '-vframes', '1', '-q:v', 10, fileuuid + 'output_%0d.jpg');
                            } else {
                                await _ffmpeg.run('-i', _input_file_name, '-filter:v',
                                    'crop=' + out_w.toString() + ':' + out_h.toString() + ':' + x.toString() + ':' + y.toString() + ',scale=\'if(gt(a,1),-2,' + _out_height + ')\':\'if(gt(a,1),' + _out_height + ',-2)\'', '-q:v', 10, fileuuid + 'output_%0d.jpg');
                            }
                        }
                    }

                    try {
                        const data = _ffmpeg.FS('readFile', fileuuid+'output_1.jpg');
                        var dataBlob = new Blob([data.buffer], {type: 'image/jpeg'});
                        var previewUrlFile = await readFileAsync(dataBlob);
                        return previewUrlFile;
                    } catch (e) {
                        // throw generateError({message:`Not able crop video for preview. Please check file(s) ${input_file_name} and/or contact admin.`});
                        console.log(`Not able crop video for preview. Please check file(s) ${input_file_name} and/or contact admin.`);
                        //reject(generateError({message:`Not able crop video for preview. Please check file(s) ${input_file_name} and/or contact admin.`}))
                        return null;
                    }
                    return null;
                } else {
                    console.log('No file to crop or width or height are zero.');
                    return null;
                }
            }

            async function cropMediaUpload(input_file, out_w, out_h, x, y) {
                console.log('cropMediaUpload');
                if (out_w > 0 && out_h > 0) {

                    // _ffmpeg.setLogging(true);
                    // _ffmpeg.setLogger(({type, message}) => {console.log(type, message);});

                    let _input_file_name = input_file.name;
                    let re = /(?:\.([^.]+))?$/;
                    let _file_ext = re.exec(_input_file_name)[1];
                    let re_type = /(?:(?!\/).)*/;
                    const _file_type_img_vid = re_type.exec(input_file.type)[0];
                    _file_ext = _file_ext ? _file_ext : '';

                    const _uuid = uuidv4();
                    const _output_file_name = _uuid + 'output.' + _file_ext;
                    const _output_metadata_name = _uuid + 'output_meta.txt'
                    const _input_metadata_name = _uuid + 'input_meta.txt'

                    async function create_file_from_data_array(data, file_obj) {
                        const blob = await new Blob([data.buffer], {type: file_obj.type});
                        return await new File([blob],
                            file_obj.name,
                            {
                                type: file_obj.type,
                                uid: file_obj.uid,
                                lastModified: file_obj.lastModified,
                                originalRelativePath: file_obj.webkitRelativePath,
                                webkitRelativePath: file_obj.webkitRelativePath
                            });
                    }

                    if (input_file instanceof File &&
                        (_file_type_img_vid.includes('video') || _file_type_img_vid.includes('image')) &&
                        out_w >= 0 && out_h >= 0) {

                        async function startCropping(_file, out_w, out_h, x, y) {
                            let _ffmpeg = await getFFMPEG();
                            const fileuuid = uuidv4();
                            _ffmpeg.FS('writeFile', fileuuid + _input_file_name, await fetchFile(_file));
                            await _ffmpeg.run('-i', fileuuid + _input_file_name, '-f', 'ffmetadata', _output_metadata_name);
                            const metadata = await _ffmpeg.FS('readFile', _output_metadata_name);
                            console.log(metadata);
                            var dec = new TextDecoder("utf-8");
                            var metadata_str = dec.decode(metadata);
                            if (metadata_str.includes('CHAPTER') ) {
                                console.log(metadata_str);
                                // const regExChapter = new regExp('[CHAPTER]', 'g');
                                const init_chapter = "[CHAPTER]\nTIMEBASE=1/10000000\nSTART=0\nEND=0\ntitle=ignore_this_chapter\n[CHAPTER]";
                                const metadata_str_new = metadata_str.replace(/\[CHAPTER\]/, init_chapter);
                                console.log("metadata_str_new");
                                console.log(metadata_str_new);

                                var enc = new TextEncoder();
                                const metadata_new = enc.encode(metadata_str_new);
                                console.log("metadata_new");
                                console.log(metadata_new);

                                _ffmpeg.FS('writeFile', fileuuid + _input_metadata_name, metadata_new);

                                await _ffmpeg.run('-i', fileuuid + _input_file_name,
                                    '-f', 'ffmetadata', '-i', fileuuid + _input_metadata_name,
                                    '-map', '0:v', '-map_chapters', '1', '-map_metadata', '1',
                                    '-filter:v',
                                    'crop=iw*' + out_w.toString() + ':ih*' + out_h.toString() + ':iw*' + x.toString() + ':ih*' + y.toString(),
                                    '-c:a', 'copy', _output_file_name);
                                    //ffmpeg -i V0001.mp4 -f ffmetadata -i in.txt -map 0:v  -map_metadata 1 -map_chapters 1 -c copy ooo.

                                _ffmpeg.FS('unlink', fileuuid + _input_metadata_name);
                                _ffmpeg.FS('unlink', _output_metadata_name);
                            } else {
                                    await _ffmpeg.run('-i', fileuuid + _input_file_name, '-filter:v',
                                        'crop=iw*' + out_w.toString() + ':ih*' + out_h.toString() + ':iw*' + x.toString() + ':ih*' + y.toString(),
                                        '-map_metadata', '0', '-c:a', 'copy', _output_file_name);
                            }

                            const data = await _ffmpeg.FS('readFile', _output_file_name);
                            let file_output = await create_file_from_data_array(data, _file);

                            // saveData(new Blob([data.buffer], { type: _file.type }), _output_file_name);

                            //process.exit(0);
                            _ffmpeg.FS('unlink', _output_file_name);
                            _ffmpeg.FS('unlink', fileuuid + _input_file_name);
                            return file_output;
                        };
                        console.log('processing started');

                        let file_output = await startCropping(input_file, out_w, out_h, x, y);
                        return file_output;
                    }
                }
                console.log('Nothing to crop.');
                return null;
            }

            async function cropMedia(input_file, out_w, out_h, x, y) {
                console.log('cropVideo');

                let duration = 0;
                const ffmpeg = createFFmpeg({ log: true, corePath: baseUrl + '/static/ffmpeg/js/ffmpeg-core.js' });

                var saveData = (function () {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    return function (blob, fileName) {
                        const url = window.URL.createObjectURL(blob);
                        console.log(url);
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    };
                }());

                function create_file_from_data_array(data, file_obj) {
                    const blob = new Blob([data.buffer], { type: file_obj.type });
                    return new File([blob],
                        file_obj.name,
                        {type: file_obj.type,
                            uid: file_obj.uid,
                            lastModified: file_obj.lastModified,
                            originalRelativePath: file_obj.webkitRelativePath,
                            webkitRelativePath: file_obj.webkitRelativePath});
                }

                if (input_file instanceof File && out_w > 0 && out_h > 0) {
                    async function startCropping(_file, out_w, out_h, x, y) {
                        await ffmpeg.load();
                        const fileuuid = uuidv4();
                        ffmpeg.FS('writeFile', fileuuid+'input.mp4', await fetchFile(_file));
                        await ffmpeg.run('-i', fileuuid+'input.mp4', '-filter:v',
                            'crop='+out_w.toString()+':'+out_h.toString()+':'+x.toString()+':'+y.toString(),
                            '-map_metadata', '0', '-c:a', 'copy', fileuuid+'output.mp4');
                        console.log(ffmpeg.log);
                        const data = ffmpeg.FS('readFile', fileuuid+'output.mp4');
                        const newUrl = URL.createObjectURL(new Blob([data.buffer], { type: _file.type }));
                        try {
                            await fs.promises.writeFile(fileuuid+'output.mp4', data);
                        } catch (e) {
                            console.log('writeFile not working');
                        }

                        let file_output = await create_file_from_data_array(data, _file);
                        console.log("file_output");
                        console.log(file_output);
                        console.log("_file");
                        console.log(_file);

                        saveData(new Blob([data.buffer], { type: _file.type }), 'output.mp4');

                        //process.exit(0);
                        console.log(data);
                        console.log(newUrl);
                        return file_output;
                    };
                    //await ffmpeg.load();
                    console.log('processing started');

                    let file_output = await startCropping(input_file, out_w, out_h, x, y);
                    return file_output;
                }

                console.log('Done cropVideo');
                return '';
            }

            async function chunkedUpload(file, data_expected_id, md5sum_accum_upload_package, progress_info, task_name=null) {

                const upBufferSize = 5 * 1048576; // Chunk size of 5 MB
                const uploadFile = file;
                const da_exp_id = data_expected_id;
                const upTotalParts = Math.ceil(uploadFile.size / upBufferSize);

                let total_progress_bytes = progress_info.start_bytes;

                const getParentDir = (file) => {
                    const path = file['webkitRelativePath'];
                    const p_split = path.split('/');
                    if (p_split.length >= 3) {
                        return p_split[1];
                    } else if (p_split.length >= 2) {
                        return p_split[0];
                    } else {
                        return '';
                    }
                }

                let upCurrentPart = 0;
                let start = null;
                let end = null;
                let upload_id = null;
                let offset = null;
                // for the case that the file size is 0 nevertheless upload it for creating a file entry
                // on the webserver
                let tmp_size = uploadFile.size;
                start = 0;
                // boolean to check if file is already known in server db
                let file_known_in_db = null;

                function create_upload_message(progress, total) {
                    const percentage = Math.floor(progress / total * 100);
                    return `Upload progress: ${percentage} %.`;
                }

                await Axios.get(`/api/v1/chunked_upload?upload_id=${uploadFile.md5sum}&filename=${uploadFile.name}`, {
                    proxy: config.proxy,
                }).then((response) => {
                    console.log('File to be uploaded already known in DB.')

                    upload_id = response.data.upload_id;
                    offset = response.data.offset;
                    start = offset;
                    total_progress_bytes += start;

                    file_known_in_db = true;

                    progress_info.on_change_alert(create_upload_message(total_progress_bytes,
                        progress_info.total_bytes),
                        Math.floor(total_progress_bytes/progress_info.total_bytes*100));

                }).catch((error) => {
                    console.log('File does not exist, thus start at zero with upload.');
                    file_known_in_db = false
                });

                let already_completed = (start >= uploadFile.size);

                // console.log('offset');
                // console.log(offset);
                // console.log('start');
                // console.log(start);
                // console.log('uploadFile.size');
                // console.log(uploadFile.size);

                // hash for checking attempts
                let md5sumchunk_prev_attempt = null;
                let attempt_upload = 1;

                // continue to upload file chunks until start equals the size of the file
                // or if a file with 0 size is unknown in the db
                while (start < uploadFile.size || (!file_known_in_db && (tmp_size === 0) )) {
                    end = Math.min(start + upBufferSize, uploadFile.size);

                    // console.log('end');
                    // console.log(end);

                    let file_chunk = uploadFile.slice(start, end);
                    let upFormData = new FormData();
                    //console.log('compute md5sum of chunk');
                    const md5sumchunk = await computeMD5sumchunk(file_chunk);
                    //console.log('Computed md5 sum of chunk');
                    //console.log(md5sumchunk);
                    //console.log('Computed md5 sum of previous chunk');
                    //console.log(md5sumchunk_prev_attempt);

                    // check
                    if (md5sumchunk_prev_attempt !== null && md5sumchunk_prev_attempt === md5sumchunk) {
                        console.warn('Attempting to upload failed chunk. Attempt no. '+ attempt_upload.toString());
                    }

                    upFormData.append('the_file', file_chunk);
                    upFormData.append('data_expected_id', da_exp_id);
                    upFormData.append('filename', uploadFile.name);
                    upFormData.append('total_file_size', uploadFile.size);
                    upFormData.append('md5expected', uploadFile.md5sum);
                    upFormData.append('md5sum_accum_upload_package', md5sum_accum_upload_package);
                    upFormData.append('parent_dir', getParentDir(uploadFile));

                    // console.log('Upload MD5SUM:')
                    // console.log(uploadFile.md5sum);

                    if (!(md5sumchunk === null)){
                        upFormData.append('md5sum_chunk', md5sumchunk);
                    }
                    if (attempt_upload !== null){
                        upFormData.append('upload_attempt', attempt_upload);
                    }

                    if (!(upload_id === null || upload_id === undefined)){
                         upFormData.append('upload_id', upload_id);
                    }

                    // console.log(`bytes ${start}-${end}/${uploadFile.size}`);

                    await Axios.post('/api/v1/chunked_upload', upFormData, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Content-Range': `bytes ${start}-${end}/${uploadFile.size}`
                        },
                    }).then((response) => {
                        // console.log('Chunk upload finished.')
                        // console.log(response.data);
                        upload_id = response.data.upload_id;
                        start = response.data.offset;
                        // console.log(upload_id);
                        // console.log(start);
                        total_progress_bytes += upBufferSize;
                        md5sumchunk_prev_attempt = null;
                        // for successful upload set attempt to 1
                        attempt_upload = 1;
                        // case of filesize 0: file upload need to be done nevertheless once
                        // that the server creates a file with 0 size and the relevant filename
                        already_completed = (tmp_size === 0) ? false : already_completed;
                        file_known_in_db = true;
                        upCurrentPart++;
                    }).catch((error) => {
                        // count failed attempt for current chunk
                        // TODO distinguish between offset error and error due to network connection error or file not able be found on disk
                        attempt_upload++;
                        md5sumchunk_prev_attempt = md5sumchunk;

                        if (error.status === 400 && error.offset){
                            console.log('Start needs to equal offset of existing file.');
                        } else {
                            console.log('Something went wrong.');
                            console.log(error);
                            console.log(error.response);
                            console.log(error.response.data);
                        }
                    });
                    progress_info.on_change_alert(create_upload_message(total_progress_bytes,
                        progress_info.total_bytes),
                        Math.floor(total_progress_bytes/progress_info.total_bytes*100));

                    if (attempt_upload !== null && attempt_upload > 100){

                        let failedUpForm = new FormData();
                        if (task_name !== null){
                            failedUpForm.append('task_name', task_name);
                        }
                        failedUpForm.append('chunk_upload_failed', true);
                        if (attempt_upload !== null){
                            failedUpForm.append('upload_attempt', attempt_upload);
                        }

                        await Axios.post('/api/v1/chunked_upload', failedUpForm, {
                            proxy: config.proxy,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).then((response) => {
                            console.warn('This is then branch of failed upload.')
                            let task_name;
                            try {
                                task_name = response.data.task_name;
                            }
                            catch (_) {
                                task_name = 'unknown';
                            }
                            throw generateError({message:`Not able to upload chunk due to interrupted internet connection. Failed to create Task ${task_name}`});
                        })
                        //    .catch((error) => {
                        //    console.warn('This is catch of failed upload.');
                        //    //console.log(error.data);
                        //    throw generateError({message:'Not able to upload chunk due to interrupted internet connection.  Failed to create Task'});
                        //});
                        console.log('AFTER POST FAILED');
                    }
                };

                // mark upload as completed if new upload in this session
                if (!already_completed) {
                    const formDataUploadComplete = new FormData();
                    formDataUploadComplete.append('upload_id', upload_id)
                    formDataUploadComplete.append('md5', uploadFile.md5sum)

                    await Axios.post('/api/v1/chunked_upload_complete', formDataUploadComplete, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then((response) => {
                        console.log(response.data);
                        console.log(`COMPLETE UPLOAD for file ${uploadFile.md5sum} and upload id ${upload_id}`)
                    });
                }

                return "Done";
            }

            function readFileSliceAsync(file, start, end) {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();

                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };

                    fileReader.onerror = () => {
                        reject('ERROR FILE NOT READABLE.');
                    };

                    fileReader.readAsArrayBuffer(file.slice(start, end, file));
                })
            }

            async function computeMD5sumchunk(file){
                const bufferSize = 50 * 2097152 / 2; // Chunk size of 50MB
                const spark = new SparkMD5.ArrayBuffer();

                let response = null;
                try {
                    const totalParts = Math.ceil(file.size / bufferSize)
                    console.log('totalParts');
                    console.log(totalParts);
                    let currentPart = 0;

                    while(currentPart < totalParts) {

                        const start = currentPart * bufferSize;
                        const end = Math.min(start + bufferSize, file.size);
                        const filechunk = await readFileSliceAsync(file, start, end);
                        spark.append(filechunk);
                        currentPart++;
                    }
                    response = spark.end();
                } catch (errorData) {
                    console.warn('Computing md5sum of upload chunk failed. Checking chunks is thus disabled.');
                }
                return response;
            }

            async function computeMD5sum(file, updateDict, bytes_processed){
                let current_bytes_proc = bytes_processed;

                function create_upload_message(progress, total) {
                    const percentage = Math.floor(progress / total * 100);
                    return `Checksum computation progress: ${percentage} %.`;
                }

                const bufferSize = 50 * 2097152 / 2; // Chunk size of 50MB
                const spark = new SparkMD5.ArrayBuffer();
                const totalParts = Math.ceil(file.size / bufferSize)
                console.log('totalParts');
                console.log(totalParts);
                let currentPart = 0;

                while(currentPart < totalParts) {

                    const start = currentPart * bufferSize;
                    const end = Math.min(start + bufferSize, file.size);

                    const filechunk = await readFileSliceAsync(file, start, end);

                    spark.append(filechunk);

                    current_bytes_proc = bytes_processed + start;
                    updateDict.on_change_alert(create_upload_message(current_bytes_proc, updateDict.totalBytes),
                        Math.floor(current_bytes_proc / updateDict.totalBytes * 100));

                    // readFileSliceAsync(file, start, end).then((chunk) => {
                    //     console.log('This is chunk of file', file.name);
                    //     spark.append(chunk);
                    // }).catch((mess) =>{
                    //     throw generateError(mess);
                    // })

                    currentPart++;
                }
                return spark.end();
            }

            async function computeMD5sumS(taskDataSpec, updateDict) {
                let bytes_processed = 0;

                console.log('function call computeMD5sum');
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (key === 'client_files' && Array.isArray(value)) {
                        for (let idx = 0; idx < value.length; idx++) {
                            console.log(value[idx]);
                            value[idx]['md5sum'] = await computeMD5sum(value[idx], updateDict, bytes_processed);

                            bytes_processed += value[idx].size;
                        }
                    }
                }
            }

            function computeTotalUploadBytes(taskDataSpec) {
                let total_bytes = 0;
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (key === 'client_files' && Array.isArray(value)) {
                        for (let idx = 0; idx < value.length; idx++) {
                            total_bytes += value[idx].size;
                        }
                    }
                }
                return total_bytes;
            }

            async function dataPrivacyPreprocessing(taskDataSpec, out_wrel=0, out_hrel=0, xrel=0, yrel=0, onUpdate=null) {

                let total_size_crpping = 0;
                let finished_size_cropping = 0;
                let current_size_cropping = 0;

                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (key === 'client_files' && Array.isArray(value)) {
                        total_size_crpping = value.reduce(
                            (prevVal, currentItem) => prevVal + currentItem.size, 0
                        );
                    }
                }
                console.log('total image / video size', total_size_crpping);

                let _ffmpeg = await getFFMPEG();
                const progressTimer = setInterval(async () => {
                    _ffmpeg.setProgress(({ratio}) => {
                        const clean_ratio = 1 >= ratio > 0 ? ratio : 0.000001;
                        const progress = (finished_size_cropping / total_size_crpping + clean_ratio * current_size_cropping / total_size_crpping) * 100;
                        const clean_progress = progress < 0 ? 0 : progress;
                        onUpdate(`Cropping media for privacy reasons.`,  clean_progress.toFixed(1));
                    });
                }, 2000);

                for await (const [key, value] of Object.entries(taskDataSpec)) {
                    if (key === 'client_files' && Array.isArray(value)) {
                        for (let idx = 0; idx < value.length; idx++) {
                            current_size_cropping = value[idx].size;
                            const file_return = await cropMediaUpload(value[idx], out_wrel, out_hrel, xrel, yrel);
                            finished_size_cropping += value[idx].size;
                            // reassign only cropped files - skip others
                            if (file_return) {
                                value[idx] = file_return;
                            }
                        }
                    }
                }
                clearInterval(progressTimer);
            }

            function  getCroppingProperties (selectedSettingTag, croppingSettingList) {
                let out_wrel = 0;
                let out_hrel = 0;
                let xrel = 0;
                let yrel = 0;

                try {
                    const {tag, x1rel, y1rel, x2rel, y2rel} = croppingSettingList.filter(item => item.tag == selectedSettingTag)[0];
                    out_wrel = x2rel - x1rel;
                    out_hrel = y2rel - y1rel;
                    xrel = x1rel;
                    yrel = y1rel
                }
                catch (e){
                    throw generateError(e);
                }
                return {out_wrel: out_wrel, out_hrel: out_hrel, xrel: xrel, yrel: yrel};
            }


            async function createTask(taskSpec, taskDataSpec, onUpdate) {
                const { backendAPI } = config;

                const debug_output = false;

                // TODO revise this implementation - this should be moved to tasks-actions.js in cvat-ui
                //  though current implementation overwrites this instantaneously
                // get current user and assign it to taskSpec.
                const user = await getSelf();
                taskSpec['assignee_id'] = user.id;
                taskSpec['assignees'] = [user.id];
                console.log('taskSpec');
                console.log(taskSpec);

                async function wait(id) {
                    return new Promise((resolve, reject) => {
                        async function checkStatus() {
                            try {
                                const response = await Axios.get(`${backendAPI}/tasks/${id}/status`);
                                if (['Queued', 'Started'].includes(response.data.state)) {
                                    if (response.data.message !== '') {
                                        onUpdate(response.data.message);
                                    }
                                    setTimeout(checkStatus, 3000);
                                } else if (response.data.state === 'Finished') {
                                    resolve();
                                } else if (response.data.state === 'Failed') {
                                    // If request has been successful, but task hasn't been created
                                    // Then passed data is wrong and we can pass code 400
                                    const message = `
                                        Could not create the task on the server. ${response.data.message}.
                                    `;
                                    reject(new ServerError(message, 400));
                                } else {
                                    // If server has another status, it is unexpected
                                    // Therefore it is server error and we can pass code 500
                                    reject(
                                        new ServerError(
                                            `Unknown task state has been received: ${response.data.state}`,
                                            500,
                                        ),
                                    );
                                }
                            } catch (errorData) {
                                reject(generateError(errorData));
                            }
                        }

                        setTimeout(checkStatus, 3000);
                    });
                }

                // preprocessing of video files comes here:
                console.log('BEFORE dataPrivacyPreprocessing');
                console.log("taskDataSpec");
                console.log(taskDataSpec);
                taskSpec.croppingSetting

                // TODO move this to constants
                const croppingSettings = [{tag: 'No cropping', x1rel: 0, y1rel: 0, x2rel: 0, y2rel: 0},
                            {tag: 'EPK-i7010', x1rel: 0.02240, y1rel: 0.05185, x2rel: 0.66073, y2rel: 0.95000},
                            {tag: 'EPK-i', x1rel: 0.01777, y1rel: 0.00495, x2rel: 0.97012, y2rel: 0.99740},
                            {tag: 'EPK-i5500c', x1rel: 0.16656, y1rel: 0.01944, x2rel: 0.83229, y2rel: 0.90666},
                        ];
                console.log(croppingSettings);
                console.log("taskSpec.croppingSetting");
                console.log(taskSpec.croppingSetting);

                const { out_wrel, out_hrel, xrel, yrel } = getCroppingProperties(taskSpec.croppingSetting, croppingSettings);
                console.log(out_wrel, out_hrel, xrel, yrel);

                let files_to_be_cropped = 0;
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (key === 'client_files' && Array.isArray(value)) {
                        for (let idx = 0; idx < value.length; idx++) {
                            if (value[idx].type.includes('video') || value[idx].type.includes('image')){
                                files_to_be_cropped += 1;
                            }
                        }
                    }
                }

                console.log("files_to_be_cropped");
                console.log(files_to_be_cropped);

                if (out_hrel > 0 && out_wrel > 0){
                    await dataPrivacyPreprocessing(taskDataSpec, out_wrel, out_hrel, xrel, yrel, onUpdate);
                }

                console.log('AFTER dataPrivacyPreprocessing');
                console.log("taskDataSpec");
                console.log(taskDataSpec);

                const taskData = new FormData();
                const total_bytes = computeTotalUploadBytes(taskDataSpec);
                console.log('total_bytes');
                console.log(total_bytes);
                console.log("taskDataSpec");
                console.log(taskDataSpec);

                onUpdate('Computing checksums for data to be uploaded.');
                try {
                    await computeMD5sumS(taskDataSpec,
                        {'on_change_alert': onUpdate, 'totalBytes': total_bytes});
                } catch (errorData) {
                    throw generateError(errorData);
                }
                onUpdate('Computing checksums for data to be uploaded: Done.');

                console.log(taskDataSpec);
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (Array.isArray(value) && !key.includes('client')) {
                        value.forEach((element, idx) => {
                            if (!key.includes("client")) {
                                console.log('prepare taskData');
                                console.log(`${key}[${idx}]`);
                                taskData.append(`${key}[${idx}]`, element);
                                console.log(`${key}[${idx}]`);
                                console.log(element);
                            }
                        });
                    } else {
                        taskData.set(key, value);
                        console.log(key);
                        console.log(value);
                    }
                }
                console.log('formData');
                // Display the key/value pairs
                for (var pair of taskData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]);
                }
                let response = null;

                onUpdate('The task is being created on the server..', null, 1);
                console.log('taskSpec');
                console.log(taskSpec);
                try {
                    response = await Axios.post(`${backendAPI}/tasks`, JSON.stringify(taskSpec), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // Example
                // let taskDataExpected = {'client_files_expected':
                //        [{'file_path':'as/as', 'md5sum':'01234567890123456789012345678901'},
                //            {'file_path':'asas/asas', 'md5sum':'21234567890123456789012345678901'}], 'files_expected': 2};

                let taskDataExpected = {client_files_expected: [],
                    files_expected: 0, name_expected: '', project_id_expected: null};
                let testFiles = [];
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    console.log("Key value in taskDataExpected.");
                    console.log(key);
                    console.log(value);
                    if (key === 'client_files') {
                        let _counter = 0;
                        if (Array.isArray(value)) {
                            value.forEach((element, idx) => {
                                testFiles.push(element);
                                _counter++;
                                let _temp_file_name = element['webkitRelativePath'].split('/').pop();
                                _temp_file_name = _temp_file_name !== '' ? _temp_file_name : element['name'];
                                taskDataExpected.client_files_expected.push({
                                    'file_path': _temp_file_name,
                                    'md5sum': element.md5sum
                                });
                            });
                            taskDataExpected.files_expected = _counter;

                        } else {
                            throw "Could not serialize expected upload files.";
                        }
                    }
                    taskDataExpected.name_expected = taskSpec.name;
                    taskDataExpected.project_id_expected = taskSpec.project_id;
                    console.log('taskDataExpected', taskDataExpected);
                }

                console.log('POST DATA', taskDataExpected);
                console.log(JSON.stringify(taskDataExpected));
                console.log(`Total size: ${total_bytes}`);


                onUpdate('Post expected data to the server..', null, 1);
                let data_expected_id = null;
                let md5sum_accum_upload_package = null;
                let md5sum_accum_already_exists = false;
                let task_id_already_exists = null;
                try{
                    if (taskDataExpected.files_expected > 0) {
                        await Axios.post(`${backendAPI}/tasks/${response.data.id}/data_expected`, taskDataExpected, {
                            proxy: config.proxy,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }).then((response) => {
                            data_expected_id = response.data['data_expected_id'];
                            md5sum_accum_upload_package = response.data['md5sum_accum_upload_package'];
                            md5sum_accum_already_exists = response.data['md5sum_accum_already_exists'];
                            task_id_already_exists = response.data['task_id_already_exists'];

                            console.log('md5sum_accum_already_exists');
                            console.log(md5sum_accum_already_exists);
                        });
                    }
                } catch (errorData) {
                    // TODO generate error message when posting expected data
                    throw generateError(errorData);
                }

                // console.log(md5sum_accum_already_exists);
                if (md5sum_accum_already_exists){
                    // await deleteTask(response.data.id);
                    console.warn("Task with identical data already exists. Skip this task");
                    await deleteTask(response.data.id);
                    return {id: task_id_already_exists, already_existing: true};
                } else {
                    onUpdate('Chunked upload (resume possible)..');
                    let uploaded_bytes = 0;
                    // UPLOAD CHUNKS
                    try {
                    for (let idx = 0; idx < testFiles.length; idx++) {
                        const finished = await chunkedUpload(testFiles[idx], data_expected_id,
                            md5sum_accum_upload_package, {
                                'start_bytes': uploaded_bytes,
                                'total_bytes': total_bytes,
                                'on_change_alert': onUpdate
                            }, taskSpec.name);
                        uploaded_bytes += testFiles[idx].size;
                        console.log('Finished uploading ' + testFiles[idx].name)
                    } } catch (errorData) {
                        // remove task if upload failed
                        try {
                            console.log('remove task after upload failed.')
                            // await deleteTask(response.data.id);
                        } catch (_) {
                            // ignore
                        }
                    }

                    onUpdate('Preparing task creation.');
                    try {
                        await Axios.post(`${backendAPI}/tasks/${response.data.id}/data`, taskData, {
                            proxy: config.proxy,
                        });
                    } catch (errorData) {
                        try {
                            await deleteTask(response.data.id);
                        } catch (_) {
                            // ignore
                        }

                        throw generateError(errorData);
                    }

                    try {
                        await wait(response.data.id);
                    } catch (createException) {
                        await deleteTask(response.data.id);
                        throw createException;
                    }

                    const createdTask = await getTasks(`?id=${response.id}`);
                    console.log(createdTask);
                    console.log(createdTask[0]);
                    return createdTask[0];
                }
            }

            async function getJob(jobID) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/jobs/${jobID}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getJobReviews(jobID) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/jobs/${jobID}/reviews`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function createReview(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/reviews`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getJobIssues(jobID) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/jobs/${jobID}/issues`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function createComment(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/comments`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function updateIssue(issueID, data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.patch(`${backendAPI}/issues/${issueID}`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function saveJob(id, jobData) {
                const { backendAPI } = config;

                try {
                    await Axios.patch(`${backendAPI}/jobs/${id}`, JSON.stringify(jobData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getUsers(filter = 'page_size=all') {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users?${filter}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data.results;
            }

            async function usrCanCreatePrj() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users/userprjcreate`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data['can_create'];
            }

            async function usrEnableCropping() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users/userenablecropping`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data['cropping_enabled'];
            }

            async function usrCroppingDefault() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users/userenablecropping`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data['cropping_default'];
            }

            async function getConcatFrames(tid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${tid}/data?type=concat_frames`, {
                        proxy: config.proxy,
                        responseType: 'json',
                    });
                } catch (errorData) {
                    const code = errorData.response ? errorData.response.status : errorData.code;
                    throw new ServerError(`Could not get concat frames for the task ${tid} from the server`, code);
                }

                return response.data;
            }

            async function getSelf() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users/self`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getPreview(tid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${tid}/data?type=preview`, {
                        proxy: config.proxy,
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    const code = errorData.response ? errorData.response.status : errorData.code;
                    throw new ServerError(`Could not get preview frame for the task ${tid} from the server`, code);
                }

                return response.data;
            }

            async function getImageContext(tid, frame) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(
                        `${backendAPI}/tasks/${tid}/data?quality=original&type=context_image&number=${frame}`,
                        {
                            proxy: config.proxy,
                            responseType: 'blob',
                        },
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getData(tid, chunk) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await workerAxios.get(
                        `${backendAPI}/tasks/${tid}/data?type=chunk&number=${chunk}&quality=compressed`,
                        {
                            proxy: config.proxy,
                            responseType: 'arraybuffer',
                        },
                    );
                } catch (errorData) {
                    throw generateError({
                        ...errorData,
                        message: '',
                        response: {
                            ...errorData.response,
                            data: String.fromCharCode.apply(null, new Uint8Array(errorData.response.data)),
                        },
                    });
                }

                return response;
            }

            async function getMeta(tid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${tid}/data/meta`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function getAnnotations(session, id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/${session}s/${id}/annotations`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function updateAnnotations(session, id, data, action) {
                const { backendAPI } = config;
                let requestFunc = null;
                let url = null;
                if (action.toUpperCase() === 'PUT') {
                    requestFunc = Axios.put.bind(Axios);
                    url = `${backendAPI}/${session}s/${id}/annotations`;
                } else {
                    requestFunc = Axios.patch.bind(Axios);
                    url = `${backendAPI}/${session}s/${id}/annotations?action=${action}`;
                }

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function uploadAnnotations(session, id, file, format) {
                const { backendAPI } = config;

                let annotationData = new FormData();
                annotationData.append('annotation_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.put(
                                `${backendAPI}/${session}s/${id}/annotations?format=${format}`,
                                annotationData,
                                {
                                    proxy: config.proxy,
                                },
                            );
                            if (response.status === 202) {
                                annotationData = new FormData();
                                setTimeout(request, 3000);
                            } else {
                                resolve();
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            // Session is 'task' or 'job'
            async function dumpAnnotations(id, name, format) {
                const { backendAPI } = config;
                const baseURL = `${backendAPI}/tasks/${id}/annotations`;
                let query = `format=${encodeURIComponent(format)}`;
                if (name) {
                    const filename = name.replace(/\//g, '_');
                    query += `&filename=${encodeURIComponent(filename)}`;
                }
                let url = `${baseURL}?${query}`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        Axios.get(`${url}`, {
                            proxy: config.proxy,
                        })
                            .then((response) => {
                                if (response.status === 202) {
                                    setTimeout(request, 3000);
                                } else {
                                    query = `${query}&action=download`;
                                    url = `${baseURL}?${query}`;
                                    resolve(url);
                                }
                            })
                            .catch((errorData) => {
                                reject(generateError(errorData));
                            });
                    }

                    setTimeout(request);
                });
            }

            async function saveLogs(logs) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/server/logs`, JSON.stringify(logs), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getLambdaFunctions() {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/functions`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function runLambdaRequest(body) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/lambda/requests`, JSON.stringify(body), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function callLambdaFunction(funId, body) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/lambda/functions/${funId}`, JSON.stringify(body), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getLambdaRequests() {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/requests`, {
                        proxy: config.proxy,
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getRequestStatus(requestID) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/requests/${requestID}`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function cancelLambdaRequest(requestId) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/lambda/requests/${requestId}`, {
                        method: 'DELETE',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            function predictorStatus(projectId) {
                const { backendAPI } = config;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.get(`${backendAPI}/predict/status?project=${projectId}`);
                            return response.data;
                        } catch (errorData) {
                            throw generateError(errorData);
                        }
                    }

                    const timeoutCallback = async () => {
                        let data = null;
                        try {
                            data = await request();
                            if (data.status === 'queued') {
                                setTimeout(timeoutCallback, 1000);
                            } else if (data.status === 'done') {
                                resolve(data);
                            } else {
                                throw new Error(`Unknown status was received "${data.status}"`);
                            }
                        } catch (error) {
                            reject(error);
                        }
                    };

                    setTimeout(timeoutCallback);
                });
            }

            function predictAnnotations(taskId, frame) {
                return new Promise((resolve, reject) => {
                    const { backendAPI } = config;

                    async function request() {
                        try {
                            const response = await Axios.get(
                                `${backendAPI}/predict/frame?task=${taskId}&frame=${frame}`,
                            );
                            return response.data;
                        } catch (errorData) {
                            throw generateError(errorData);
                        }
                    }

                    const timeoutCallback = async () => {
                        let data = null;
                        try {
                            data = await request();
                            if (data.status === 'queued') {
                                setTimeout(timeoutCallback, 1000);
                            } else if (data.status === 'done') {
                                predictAnnotations.latestRequest.fetching = false;
                                resolve(data.annotation);
                            } else {
                                throw new Error(`Unknown status was received "${data.status}"`);
                            }
                        } catch (error) {
                            predictAnnotations.latestRequest.fetching = false;
                            reject(error);
                        }
                    };

                    const closureId = Date.now();
                    predictAnnotations.latestRequest.id = closureId;
                    const predicate = () => !predictAnnotations.latestRequest.fetching || predictAnnotations.latestRequest.id !== closureId;
                    if (predictAnnotations.latestRequest.fetching) {
                        waitFor(5, predicate).then(() => {
                            if (predictAnnotations.latestRequest.id !== closureId) {
                                resolve(null);
                            } else {
                                predictAnnotations.latestRequest.fetching = true;
                                setTimeout(timeoutCallback);
                            }
                        });
                    } else {
                        predictAnnotations.latestRequest.fetching = true;
                        setTimeout(timeoutCallback);
                    }
                });
            }

            predictAnnotations.latestRequest = {
                fetching: false,
                id: null,
            };

            async function installedApps() {
                const { backendAPI } = config;
                try {
                    const response = await Axios.get(`${backendAPI}/server/plugins`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function createCloudStorage(storageDetail) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/cloudstorages`, JSON.stringify(storageDetail), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function updateCloudStorage(id, cloudStorageData) {
                const { backendAPI } = config;

                try {
                    await Axios.patch(`${backendAPI}/cloudstorages/${id}`, JSON.stringify(cloudStorageData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getCloudStorages(filter = '') {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/cloudstorages?page_size=12&${filter}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function getCloudStorageContent(id, manifestPath) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/content${
                        manifestPath ? `?manifest_path=${manifestPath}` : ''
                    }`;
                    response = await Axios.get(url, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getCloudStoragePreview(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/preview`;
                    response = await workerAxios.get(url, {
                        proxy: config.proxy,
                        responseType: 'arraybuffer',
                    });
                } catch (errorData) {
                    throw generateError({
                        ...errorData,
                        message: '',
                        response: {
                            ...errorData.response,
                            data: String.fromCharCode.apply(null, new Uint8Array(errorData.response.data)),
                        },
                    });
                }

                return new Blob([new Uint8Array(response)]);
            }

            async function getCloudStorageStatus(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/status`;
                    response = await Axios.get(url, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function deleteCloudStorage(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/cloudstorages/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    server: {
                        value: Object.freeze({
                            about,
                            share,
                            formats,
                            exception,
                            login,
                            logout,
                            changePassword,
                            requestPasswordReset,
                            resetPassword,
                            authorized,
                            register,
                            request: serverRequest,
                            userAgreements,
                            installedApps,
                        }),
                        writable: false,
                    },

                    projects: {
                        value: Object.freeze({
                            get: getProjects,
                            searchNames: searchProjectNames,
                            save: saveProject,
                            create: createProject,
                            delete: deleteProject,
                            exportDataset: exportDataset('projects'),
                        }),
                        writable: false,
                    },

                    videoProcessing: {
                        value: Object.freeze({
                            cropMedia: cropMedia ,
                            cropPreview: cropPreview,
                            cropPreviewProcessor: cropPreviewProcessor,
                            cropPreviewProcessorList: cropPreviewProcessorList,
                        }),
                        writeable: false,
                    },

                    tasks: {
                        value: Object.freeze({
                            getTasks,
                            saveTask,
                            createTask,
                            deleteTask,
                            exportDataset: exportDataset('tasks'),
                            exportTask,
                            importTask,
                        }),
                        writable: false,
                    },

                    jobs: {
                        value: Object.freeze({
                            get: getJob,
                            save: saveJob,
                            issues: getJobIssues,
                            reviews: {
                                get: getJobReviews,
                                create: createReview,
                            },
                        }),
                        writable: false,
                    },

                    users: {
                        value: Object.freeze({
                            get: getUsers,
                            self: getSelf,
                            usercancreateprj: usrCanCreatePrj,
                            userEnableCropping: usrEnableCropping,
                            userCroppingDefault: usrCroppingDefault,
                        }),
                        writable: false,
                    },

                    frames: {
                        value: Object.freeze({
                            getData,
                            getMeta,
                            getPreview,
                            getImageContext,
                            getConcatFrames,
                        }),
                        writable: false,
                    },

                    annotations: {
                        value: Object.freeze({
                            updateAnnotations,
                            getAnnotations,
                            dumpAnnotations,
                            uploadAnnotations,
                        }),
                        writable: false,
                    },

                    logs: {
                        value: Object.freeze({
                            save: saveLogs,
                        }),
                        writable: false,
                    },

                    lambda: {
                        value: Object.freeze({
                            list: getLambdaFunctions,
                            status: getRequestStatus,
                            requests: getLambdaRequests,
                            run: runLambdaRequest,
                            call: callLambdaFunction,
                            cancel: cancelLambdaRequest,
                        }),
                        writable: false,
                    },

                    issues: {
                        value: Object.freeze({
                            update: updateIssue,
                        }),
                        writable: false,
                    },

                    comments: {
                        value: Object.freeze({
                            create: createComment,
                        }),
                        writable: false,
                    },

                    predictor: {
                        value: Object.freeze({
                            status: predictorStatus,
                            predict: predictAnnotations,
                        }),
                        writable: false,
                    },

                    cloudStorages: {
                        value: Object.freeze({
                            get: getCloudStorages,
                            getContent: getCloudStorageContent,
                            getPreview: getCloudStoragePreview,
                            getStatus: getCloudStorageStatus,
                            create: createCloudStorage,
                            delete: deleteCloudStorage,
                            update: updateCloudStorage,
                        }),
                        writable: false,
                    },
                }),
            );
        }
    }

    const serverProxy = new ServerProxy();
    module.exports = serverProxy;
})();
