// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Alert from 'antd/lib/alert';
import { Progress } from 'antd';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { Spin, Skeleton} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import platform from 'platform';
import ConnectedFileManager from 'containers/file-manager/file-manager';
import LabelsEditor from 'components/labels-editor/labels-editor';
import { Files } from 'components/file-manager/file-manager';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import ProjectSearchField from '../create-task-page/project-search-field'
import AdvancedConfigurationForm, { AdvancedConfiguration } from '../create-task-page/advanced-configuration-form';
import CroppingConfigurationForm, { CroppingConfiguration } from "./cropping-configuration-form";
import {CreateTaskData} from '../create-task-page/create-task-content';
//import {CreatePatientsData} from "../create-patients-page/create-patients-content";
import { OpenCvProvider, useOpenCv } from 'opencv-react';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
// import { fs } from 'fs';
// import FFMPEG from "react-ffmpeg";
// import * as cropMedia from 'cropMedia/crop-media';
// import * as cropMedia from module('cropMedia/crop-media');
// import * as CropMedia from 'cropMedia/crop-media';
import getCore from 'cvat-core-wrapper';
import hash from "object-hash";
import Modal from 'antd/lib/modal';

const engine = platform.layout || 'unknown';

export interface CreatePatientsData {
    projectId: number | null;
    basic: BaseConfiguration;
    subset: string;
    advanced: AdvancedConfiguration;
    labels: any[];
    files: Files;
    activeFileManagerTab: string;
    tasks: CreateTaskData[];
}

interface Props {
    onCreate: (data: CreateTaskData) => void;
    status: string;
    upload_progress: number | null;
    taskId: number | null;
    projectId: number | null;
    taskName: string | null;
    expectedDir: string | null;
    installedGit: boolean;
    croppingEnabled: boolean | null;
    croppingDefault: string | null;
}

type State = CreateTaskData;

const defaultState = {
    projectId: null,
    basic: {
        name: '',
    },
    subset: '',
    advanced: {
        lfs: false,
        useZipChunks: false,
        useCache: false,
    },
    labels: [],
    files: {
        local: [],
        share: [],
        remote: [],
    },
    activeFileManagerTab: 'local',
    advancedConfiguration: false,
    tasks: [],
    state_current_task_create: undefined,
    state_total_tasks: undefined,
    fileHash: '',
    fileHashPrev: '',
    croppingPreviewListStarted: false,
    croppingPreviewListStartedPrev: false,
    croppingPreviewListReady: false,
    croppingPreviewListSrcDict: null,
    croppingSelection: 'No cropping',
    croppingFilesExceedSize: false,
    fileHashPreSubmit: '',
    fileHashPrevPreSubmit: '',
    croppingPreviews: {
        first: {
            croppingPreviewStarted: false,
            croppingPreviewReady: false,
            croppingPreviewSrc: null,
        },
        second: {
            croppingPreviewStarted: false,
            croppingPreviewReady: false,
            croppingPreviewSrc: null,
        },
        nocropping: {
            croppingPreviewStarted: false,
            croppingPreviewReady: false,
            croppingPreviewSrc: null,
        },
    },
};

class CreatePatientsContent extends React.PureComponent<Props & RouteComponentProps, State> {
    private basicConfigurationComponent: RefObject<BasicConfigurationForm>;
    private advancedConfigurationComponent: RefObject<AdvancedConfigurationForm>;
    private fileManagerContainer: any;
    private croppingConfigurationComponent: RefObject<CroppingConfigurationForm>;
    private sharedArrayBufferAvailable: boolean;

    public constructor(props: Props & RouteComponentProps) {
        super(props);

        this.state = {...defaultState};
        this.basicConfigurationComponent = React.createRef<BasicConfigurationForm>();
        this.advancedConfigurationComponent = React.createRef<AdvancedConfigurationForm>();
        this.croppingConfigurationComponent = React.createRef<CroppingConfigurationForm>();
        this.sharedArrayBufferAvailable = true;
    }

    public componentDidMount(): void {
        const { projectId, taskName, croppingEnabled, croppingDefault } = this.props;

        try {
            let s = new SharedArrayBuffer(4);
            this.sharedArrayBufferAvailable = true;
            console.log("this.sharedArrayBufferAvailable");
            console.log(this.sharedArrayBufferAvailable);
        } catch (e) {
            this.sharedArrayBufferAvailable = false;
            console.log('SharedArrayBuffer not available');
        }

        if (croppingEnabled && !this.sharedArrayBufferAvailable){
            this.privacySettingsNotAvialable('Privacy data settings are available for Chrome only. Please make sure that you provide anonymized data.');
        } else if (croppingEnabled && croppingDefault && croppingEnabled !== '') {
            // update default setting for processor
            this.croppingConfigurationComponent.current.setVal(croppingDefault);
            this.handleSubmitCroppingConfiguration(croppingDefault);
        }

        if (projectId) {
            this.handleProjectIdChange(projectId);
        }
        console.log('taskName');
        console.log(taskName);
        if (taskName) {
            this.handleNameChange(taskName);
        }

        window.addEventListener('beforeunload', this.beforeUnloadCallback);
    }

    public componentDidUpdate(prevProps: Props): void {
        const {status, history, taskId, projectId } = this.props;
        const {state_current_task_create, state_total_tasks } = this.state;

        if (status === 'CREATED' && prevProps.status !== 'CREATED') {
            if (state_current_task_create === state_total_tasks && state_current_task_create >= 1) {
                const btn = <Button onClick={() => history.push(`/projects/${projectId}`)}>Open project</Button>;
                notification.info({
                    message: 'Upload successfully finished',
                    duration: 0,
                    description: 'Provided data has been uploaded. You can close the window or go back' +
                        ' to the project.',
                    btn
                })
            }

            // const btn = <Button onClick={() => history.push(`/tasks/${taskId}`)}>Open task</Button>;
            // notification.info({
            //     message: 'The task has been created',
            //     btn,
            //     className: 'cvat-notification-create-patients-success',
            // });

            if (this.basicConfigurationComponent.current) {
                this.basicConfigurationComponent.current.resetFields();
            }
            if (this.advancedConfigurationComponent.current) {
                this.advancedConfigurationComponent.current.resetFields();
            }

            if (this.croppingConfigurationComponent.current) {
                this.croppingConfigurationComponent.current.resetFields();
            }

            this.fileManagerContainer.reset();

        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('beforeunload', this.beforeUnloadCallback);
    }

    private privacySettingsNotAvialable = (message: string) => {
        Modal.warning({
            title: 'Privacy Data Settings Unavailable',
            content: (
                <>
                    <Row>
                        <Col>
                            <Text>
                                {message}
                            </Text>
                        </Col>
                    </Row>
                </>
            ),
            className: 'cvat-modal-unsupported-platform-warning',
        });
    };

    private validateLabelsOrProject = (): boolean => {
        const {projectId, labels} = this.state;
        return !!labels.length || !!projectId;
    };

    private validateFilesUpdateUploader = (): boolean => {
        const files = this.fileManagerContainer.getFiles();

        const flatFiles = [].concat.apply([], Object.values(files));
        const filesExceedWasmMemoryLimit = flatFiles.filter( item => item.size > 1899999999);
        const isBlinkBasedBrowser = engine === 'Blink';
        const exceedFileSize = filesExceedWasmMemoryLimit.length > 0 && isBlinkBasedBrowser;

        this.setState({
            croppingPreviewListStarted: false,
            croppingPreviewListStartedPrev: false,
            croppingPreviewListReady: false,
            croppingPreviewListSrcDict: null,
            croppingFilesExceedSize: exceedFileSize,
            files,
        }, () => {});
    };

    private validateFiles = (): boolean => {
        const files = this.fileManagerContainer.getFiles();

        this.setState({
            files,
        });
        const totalLen = Object.keys(files).reduce((acc, key) => acc + files[key].length, 0);

        return !!totalLen;
    };

    private isCaptureStationFileStructure = (fileList: any[]): boolean => {

        let containsDump = false;
        let containsCapture = false;

        fileList.forEach((file, idx, arr) => {
            const path = file.webkitRelativePath;
            if (path.includes('Dump')) {
                containsDump = true;
            }
            if (path.includes('Capture')) {
                containsCapture = true;
            }
        });

        return containsDump && containsCapture;
    }

    private parsePatientDirectory = (patientDir: any [], tasks: any [], sourceDev: string = "") => {

        let patientDirChildren = patientDir['children'];
        console.log("sourceDev");
        console.log(sourceDev);
        console.log("patientDir['children']");
        console.log(patientDir['children']);

        // check if there are subdirectories which should not exist - raise warning then
        if (sourceDev !== "" && sourceDev !== "leuven") {
            const hasChildren = ( item : object ) => {
                if (item.hasOwnProperty('children')) {
                    if ( item['children'].length > 0 ) {
                        notification.warn({
                            message: 'Subdirectories in patient directories',
                            description: `Patient directories should not contain further directories. Subdirectory ${item['name']} in ${patientDir['name']} is ignored`,
                        });
                    }

                    return item['children'].length > 0;
                } else {
                    return false;
                }
            };

            patientDirChildren = patientDirChildren.filter( item => !hasChildren(item) )
        }

        console.log("patientDirChildren");
        console.log(patientDirChildren);

        let tempFileList = patientDirChildren.map((child) => child.file);
        let filteredFileList = [];
        console.log('tempFileList')
        console.log(tempFileList);

        let counter_img = 0;
        let counter_vid = 0;
        tempFileList.map(file => {
            const fileNameLower = file.name.toLowerCase();
            if (fileNameLower.includes('.png') ||
                fileNameLower.includes('.jpg') ||
                fileNameLower.includes('.jpeg') || fileNameLower.includes('.ppm')) {
                counter_img += 1;
            }
            if (fileNameLower.includes('.mp4') ||
                fileNameLower.includes('.mkv') ||
                fileNameLower.includes('.avi')) {
                counter_vid += 1;
            }
        });

        let vid_file_counter = 0;
        if (false) {
            //if (counter_img > 0 && counter_vid > 0) {
            console.log('Warning: Directory includes image and video data.')
            tempFileList.map(file => {
                const fileNameLower = file.name.toLowerCase();
                if (fileNameLower.includes('.png') ||
                    fileNameLower.includes('.jpg') ||
                    fileNameLower.includes('.jpeg') ||
                    fileNameLower.includes('.ppm') || fileNameLower.includes('.pdf')) {
                    console.log('skip', fileNameLower)
                } else if (vid_file_counter >= 0) {
                    filteredFileList.push(file);
                    vid_file_counter += 1;
                }
            });
        } else {
            filteredFileList = tempFileList;
        }

        tasks.push({name: patientDir['name'], fileList: filteredFileList,});
    }

    private parseParentDirectoryLeuven = (excelFileList: File [], fullFileList: any [], tasks: any []) => {

        function removeDuplicates(arr) {
            return arr.filter((item, index) => arr.indexOf(item) === index);
        }

        function renameFile(originalFile: File, newName: string): File {
            return new File([originalFile], newName, {
                uid: originalFile.uid,
                webkitRelativePath: originalFile.webkitRelativePath,
                type: originalFile.type,
                lastModified: originalFile.lastModified,
            });
        }

        // filter function for files starting with RBM and ending with mp4 extension. Remove mp4 limitation??
        const filterRBMvideos = (fileInput) => {
            // filter directories
            if ('children' in fileInput && fileInput.children.length > 0) {
                return false;
            } else if ('children' in fileInput && fileInput.children.length == 0) {
                return fileInput.name.toLowerCase().startsWith('rbm') && fileInput.name.toLowerCase().endsWith('mp4');
            }
        }

        const filterFilesStartsWith = (fileInput, startsWithString, fileNamesToIgnore: string[] = []) => {
            // filter directories
            if ('name' in fileInput && !fileNamesToIgnore.includes(fileInput.name) && fileInput.name.toLowerCase().startsWith(startsWithString.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        }

        const filesListRBM = fullFileList.filter(filterRBMvideos);
        let rbmList = filesListRBM.map( fileItem =>  fileItem.name.toUpperCase().match(/(rbm\d+)(?:-(\d+))?/i) );
        rbmList = rbmList.filter( item => item !== null );
        rbmList = rbmList.map( item => item[0] );
        rbmList = removeDuplicates(rbmList);
        // sort list of RBM numbers according length to first use
        try {
            rbmList = rbmList.sort( (a,b) => b.length - a.length);
        } catch (error) {
            console.log(error);
        }

        const fileNamesUsed: string[] = [];

        rbmList.forEach( taskNameMatch => {
            if (taskNameMatch != null){
                const taskName = taskNameMatch;

                let medCapFiles = filesListRBM.filter( item => filterFilesStartsWith(item, taskName, fileNamesUsed) );

                medCapFiles.map( item => fileNamesUsed.push(item.name) );

                medCapFiles = medCapFiles.map( child => {
                    return renameFile(child.file, 'medCap_'+ child.file.name);
                });

                let tempFileList = excelFileList;
                tempFileList = tempFileList.concat(medCapFiles);

                tasks.push({name: taskName, fileList: tempFileList,});
            }
        });

        console.log("tasks parsed via parseParentDirectoryLeuven");
        console.log(tasks);
    }

    private parsePatientDirectoryLeuven = (excelFileList: File [], patientDir: any [], tasks: any []) => {

        let imgVidSnippetsWOmCapFiles = patientDir['children'].filter( child => child['children'].length == 0 ).map( child => child.file );
        let medCapDir = patientDir['children'].filter( child => child['children'].length > 0 );
        let medCapFiles = [];
        let tempFileList = excelFileList;

        console.log("tempFileList");
        console.log(tempFileList);

        function renameFile(originalFile: File, newName: string): File {
            return new File([originalFile], newName, {
                uid: originalFile.uid,
                webkitRelativePath: originalFile.webkitRelativePath,
                type: originalFile.type,
                lastModified: originalFile.lastModified,
            });
        }

        if (medCapDir.length > 1) {
            notification.warning({
                message: 'Multiple MediCapture Directories for Patient',
                description: `Multiple MediCapture directories identified in patient directory ${patientDir['name']}. Please make sure MediCapture recordings are unique per patient directory.`,
            });
        } else if (medCapDir.length == 0) {
            notification.warning({
                message: 'MediCapture Files Unavailable',
                description: `MediCapture files have not been provided for patient directory: ${patientDir['name']}`,
            });
        } else {
            medCapFiles = medCapDir[0]['children'].map( child => {
                return renameFile(child.file, 'medCap_'+ child.file.name);
            });
        }

        console.log("medCapFiles");
        console.log(medCapFiles);
        console.log("imgVidSnippetsWOmCapFiles");
        console.log(imgVidSnippetsWOmCapFiles);


        tempFileList = tempFileList.concat(imgVidSnippetsWOmCapFiles, medCapFiles);

        console.log("tempFileList");
        console.log(tempFileList);

        // let counter_img = 0;
        // let counter_vid = 0;
        // tempFileList.map(file => {
        //     const fileNameLower = file.name.toLowerCase();
        //     if (fileNameLower.includes('.png') ||
        //         fileNameLower.includes('.jpg') ||
        //         fileNameLower.includes('.jpeg') || fileNameLower.includes('.ppm')) {
        //         counter_img += 1;
        //     }
        //     if (fileNameLower.includes('.mp4') ||
        //         fileNameLower.includes('.mkv') ||
        //         fileNameLower.includes('.avi')) {
        //         counter_vid += 1;
        //     }
        // });
        //
        // let vid_file_counter = 0;
        // if (false) {
        //     //if (counter_img > 0 && counter_vid > 0) {
        //     console.log('Warning: Directory includes image and video data.')
        //     tempFileList.map(file => {
        //         const fileNameLower = file.name.toLowerCase();
        //         if (fileNameLower.includes('.png') ||
        //             fileNameLower.includes('.jpg') ||
        //             fileNameLower.includes('.jpeg') ||
        //             fileNameLower.includes('.ppm') || fileNameLower.includes('.pdf')) {
        //             console.log('skip', fileNameLower)
        //         } else if (vid_file_counter >= 0) {
        //             filteredFileList.push(file);
        //             vid_file_counter += 1;
        //         }
        //     });
        // } else {
        //     filteredFileList = tempFileList;
        // }

        tasks.push({name: patientDir['name'], fileList: tempFileList,});
    }

    private isLeuvenFileStructure = (fileList) => {
        // make this function more el
        const containsExcelBiopsy = fileList.filter(
            (item) => item.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet").length >= 1;

        return containsExcelBiopsy;
    }

    private getLeuvenExcelFile = (fileList) => {
        // make this function more el
        const excelFileList = fileList.filter(
            (item) => item.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));

        if (excelFileList.length > 1) {
            notification.error({
                message: 'Multiple Excel Sheets Detected',
                description: `Make sure to provide a single excel file per upload containing biopsy information of all patients.`,
            });
        } else if (excelFileList.length == 0) {
            console.log('This should not happen, since arm selected due to existing excel sheet.');
        }

        return excelFileList;
    }

    private screenFileStructure = (): { success: boolean, tasks: any [] } => {
        const files = this.fileManagerContainer.getFiles();
        const fileKeys = Object.keys(files);
        let sourceDevice = "";

        console.log('screenFileStructure');

        let result = [];
        let tasks = [];
        let level = {result};

        if (fileKeys.includes('local')) {
            console.log(files['local']);
            const fileList = files['local'];

            fileList.forEach((file, idx, arr) => {
                const path = file.webkitRelativePath;
                path.split('/').reduce((r, name, i, a) => {
                    if (!r[name]) {
                        r[name] = {result: []};
                        r.result.push({name, children: r[name].result, index: idx, file: fileList[idx]})
                    }
                    return r[name];
                }, level)
            });

            console.log('Result from screenFileStructure');
            console.log(result);

            if (this.isLeuvenFileStructure(fileList)) {
                sourceDevice = "leuven";

                const excelFileList = this.getLeuvenExcelFile(fileList);

                // case if there are just files provided in the parent directory
                this.parseParentDirectoryLeuven(excelFileList, result[0].children, tasks);

                // case if parent directory has been selected
                result[0].children.forEach(patientDir => {
                    // the case if there are directories for each examination (RBMxyz as directory)
                    if ('children' in patientDir && patientDir.children.length >= 1) {
                        this.parsePatientDirectoryLeuven(excelFileList, patientDir, tasks);
                    }
                });

                if (tasks.length == 0) {
                    this.parsePatientDirectory(result[0], tasks, sourceDevice);
                }

                console.log(result);
                console.log(tasks);

            } else if (this.isCaptureStationFileStructure(fileList)) {
                sourceDevice = "capturestation";
                console.log(result);

                result[0].children.forEach(capdumpfolder => {
                    if (capdumpfolder['name'] === "Dump") {
                        capdumpfolder.children.forEach(patientDir => {
                            this.parsePatientDirectory(patientDir, tasks, sourceDevice);
                        });
                    }
                });
                console.log('Parsed tasks ', tasks);
                // throw new Error('Capture station file structure is currently not implemented.');
            } else {
                sourceDevice = "medicapture";

                console.log("Inside medicapture arm");
                console.log(sourceDevice);

                // case if parent directory has been selected
                result[0].children.forEach(patientDir => {
                    if ('children' in patientDir && patientDir.children.length >= 1) {
                        this.parsePatientDirectory(patientDir, tasks, sourceDevice);
                    }
                });
                if (tasks.length == 0) {
                    this.parsePatientDirectory(result[0], tasks, sourceDevice);
                }

                console.log(result);
                console.log(tasks);
            }
        }
        return {success: true, tasks: tasks};
    }


    private handleTasksChange = (value: []): void => {
        const taskList = [];

        let {tasks} = this.state
        console.log(tasks);

        value.forEach((e, idx, arr) => {
            taskList.push({
                projectId: this.state.projectId,
                basic: {name: this.state.basic.name + "-" + e['name']},
                subset: this.state.subset,
                advanced: this.state.advanced,
                advancedConfiguration: this.state.advancedConfiguration,
                labels: this.state.labels,
                files: {local: e['fileList'], remote: [], share: []},
                activeFileManagerTab: this.state.activeFileManagerTab,
                croppingSetting: this.state.croppingSelection,
            });
        });
        console.log(taskList);
        this.setState({
            tasks: taskList,
            state_total_tasks: taskList.length,
        });
    }


    private handleProjectIdChange = (value: null | number): void => {
        const {projectId, subset} = this.state;

        this.setState({
            projectId: value,
            subset: value && value === projectId ? subset : '',
        });
    };

    private handleNameChange = (value: null | string): void => {
        this.setState({
            basic: {
                name: value,
            },
        });

        this.basicConfigurationComponent.current.setVal(value);
        this.basicConfigurationComponent.current.setDisabled(true);
    };

    private handleSubmitBasicConfiguration = (values: BaseConfiguration): void => {
        this.setState({
            basic: {...values},
        });
    };

    private handleSubmitCroppingConfiguration = (values: CroppingConfiguration): void => {
        console.log("handleSubmitCroppingConfiguration");
        console.log(values);
        let set_val = typeof values == 'string' ? values : Object.values(values)[0];
        console.log(set_val);
        this.setState({
            croppingSelection: set_val,
        });
    };

    private handleSubmitAdvancedConfiguration = (values: AdvancedConfiguration): void => {
        this.setState({
            advanced: {...values},
        });
    };

    private handlePatientsSubsetChange = (value: string): void => {
        this.setState({
            subset: value,
        });
    };

    private changeFileManagerTab = (key: string): void => {
        const values = this.state;
        this.setState({
            ...values,
            activeFileManagerTab: key,
        });
    };

    private beforeUnloadCallback = (event: BeforeUnloadEvent): string | undefined => {
        const {status} = this.props;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED';

        if (loading) {
            const confirmationMessage = 'Upload is still in progress. Closing the window cancels the upload.';
            // eslint-disable-next-line no-param-reassign
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
        return undefined;
    };

    private validateFilesClick = (): void => {
        this.validateFiles();
        const {success, tasks} = this.screenFileStructure();
        // if (this.validateFiles()) {
        //     const {files} = this.state;
        //     const fileKeys = Object.keys(files);
        //     const data = useOpenCv();
        //     const cv = data.cv;
        //
        //     if (fileKeys.includes('local')) {
        //         const fileList = files['local'];
        //         console.log(fileList);
        //         const videoFiles = fileList.filter( entry => entry.type.includes("image"));
        //         if (videoFiles.length > 0) {
        //             console.log("video identified");
        //             let mat = cv.imread(videoFiles[0]);
        //             console.log(mat.shape);
        //             //cv.imageSizes('canvasOutput', mat);
        //             mat.delete();
        //         }
        //
        //     }
        // }
    };

    private handleSubmitClick = (): void => {
        const {success, tasks} = this.screenFileStructure();

        const screenFileSuccess = success;
        const taskList = tasks;


        if (!screenFileSuccess) {
            notification.error({
                message: 'This is for testing patients page',
                description: 'Screen file structure.',
                className: 'cvat-notification-create-patients-fail',
            });
            return;
        }

        if (!this.validateLabelsOrProject()) {
            notification.error({
                message: 'Could not create a task',
                description: 'A task must contain at least one label or belong to some project',
                className: 'cvat-notification-create-patients-fail',
            });
            return;
        }

        if (!this.validateFiles()) {
            notification.error({
                message: 'Could not create a task',
                description: 'A task must contain at least one file',
                className: 'cvat-notification-create-patients-fail',
            });
            return;
        }

        if (this.basicConfigurationComponent.current) {
            this.basicConfigurationComponent.current
                .submit()
                .then(() => {
                    if (this.advancedConfigurationComponent.current) {
                        return this.advancedConfigurationComponent.current.submit();
                    }
                    if (this.croppingConfigurationComponent.current) {
                        return this.croppingConfigurationComponent.current.submit()
                    }
                    return Promise.resolve();
                })
                .then((): void => {
                    this.handleTasksChange(taskList);
                    const {onCreate, projectId, history} = this.props;
                    // console.log(this.state.tasks[0]);
                    // onCreate(this.state.tasks[0]);
                    let counter_current_task = 0;
                    const total_tasks = this.state.tasks.length;
                    this.setState({
                        state_current_task_create: counter_current_task,
                    });

                    const submitTasks = async () => {

                        for await (const task of this.state.tasks) {
                            console.log(task);
                            console.log('submitTasks');
                            counter_current_task += 1;
                            this.setState({
                                state_current_task_create: counter_current_task,
                                state_total_tasks: total_tasks,
                            });
                            await onCreate(task);
                        }
                        setTimeout(() => {
                            this.setState({
                                state_current_task_create: undefined,
                                state_total_tasks: undefined,
                            });
                        }, 5000);
                    }
                    submitTasks();


                    // this.state.tasks.forEach(task => {
                    //     console.log(task);
                    //     onCreate(task).then((): void => {
                    //         counter_current_task += 1;
                    //         this.setState({
                    //             state_current_task_create: counter_current_task,
                    //         });
                    //     });
                    // });
                    /*
                    if (projectId) {
                        history.push(`/projects/${projectId}`);
                    } else {
                        history.push('/projects');
                    }

                     */
                }).catch((error: Error | ValidateErrorEntity): void => {
                notification.error({
                    message: 'Could not create a task',
                    description: (error as ValidateErrorEntity).errorFields ?
                        (error as ValidateErrorEntity).errorFields
                            .map((field) => `${field.name} : ${field.errors.join(';')}`)
                            .map((text: string): JSX.Element => <div>{text}</div>) :
                        error.toString(),
                    className: 'cvat-notification-create-patients-fail',
                });
            });
        }
    };

    private renderBasicBlock(): JSX.Element {
        const taskName = this.state.basic.name;
        return (
            <Col span={24}>
                <BasicConfigurationForm
                    ref={this.basicConfigurationComponent}
                    onSubmit={this.handleSubmitBasicConfiguration}
                    value={taskName}
                />
            </Col>
        );
    }

    private renderProjectBlock(): JSX.Element {
        const {projectId} = this.state;

        return (
            <>
                <Col span={24}>
                    <Text className='cvat-text-color'>Project:</Text>
                </Col>
                <Col span={24}>
                    <ProjectSearchField onSelect={this.handleProjectIdChange} value={projectId} disabled={projectId}/>
                </Col>
            </>
        );
    }

    private renderSubsetBlock(): JSX.Element | null {
        const {projectId, subset} = this.state;

        /*
                if (projectId !== null) {
                    return (
                        <>
                            <Col span={24}>
                                <Text className='cvat-text-color'>Subset:</Text>
                            </Col>
                            <Col span={24}>
                                <ProjectSubsetField
                                    value={subset}
                                    onChange={this.handlePatientsSubsetChange}
                                    projectId={projectId}
                                />
                            </Col>
                        </>
                    );
                }
        */

        return null;
    }

    private renderLabelsBlock(): JSX.Element {
        const {projectId, labels} = this.state;

        if (projectId) {
            return (
                <>
                    <Col span={24}>
                        <Text className='cvat-text-color'>Labels:</Text>
                    </Col>
                    <Col span={24}>
                        <Text type='secondary'>Project labels will be used</Text>
                    </Col>
                </>
            );
        }

        return (
            <Col span={24}>
                <Text type='danger'>* </Text>
                <Text className='cvat-text-color'>Labels:</Text>
                <LabelsEditor
                    labels={labels}
                    onSubmit={(newLabels): void => {
                        this.setState({
                            labels: newLabels,
                        });
                    }}
                />
            </Col>
        );
    }

    private renderFilesBlock(enableDir = false): JSX.Element {
        return (
            <Col span={24}>
                <Text type='danger'>* </Text>
                <Text className='cvat-text-color'>Select files:</Text>
                <ConnectedFileManager
                    onChangeActiveKey={this.changeFileManagerTab}
                    ref={(container: any): void => {
                        this.fileManagerContainer = container;
                    }}
                    withRemote
                    withDirUpload={enableDir}
                    validateFilesUpdateUploader={this.validateFilesUpdateUploader}
                />
            </Col>
        );
    }

    private renderFileRequirementBlock(): JSX.Element {
        const {expectedDir} = this.props;

        return (
            ((expectedDir !== null) ?
                <Col span={24}>
                    <Alert
                        message="Informational Notes"
                        description={`Upload the expected directory ${expectedDir}.`}
                        type="info"
                        showIcon
                    />
                </Col> : <Col span={24}/>)
        );
    }

    private renderAdvancedBlock(rendAdBlock = true): JSX.Element {
        const {installedGit} = this.props;
        const {activeFileManagerTab} = this.state;

        if (rendAdBlock) {
            return (
                <Col span={24}>
                    <Collapse>
                        <Collapse.Panel key='1' header={<Text className='cvat-title'>Advanced configuration</Text>}>
                            <AdvancedConfigurationForm
                                installedGit={installedGit}
                                activeFileManagerTab={activeFileManagerTab}
                                ref={this.advancedConfigurationComponent}
                                onSubmit={this.handleSubmitAdvancedConfiguration}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            );
        } else {
            return (
                <Col span={24}>
                </Col>
            );
        }
    }

private generateCroppingPreviewList(settingList: []) {
    const {files, croppingPreviewListStarted, croppingPreviewListReady, croppingPreviewListSrcDict} = this.state;

    const filesTemp = files;
    const fileKeys = Object.keys(filesTemp);

    const processCropping = async () => {
        if (fileKeys.includes('local') && (this.state.fileHash !== this.state.fileHashPrev)) {
            const fileList = filesTemp['local'];
            const videoFiles = fileList.filter(entry => entry.type.includes("video"));
            const imageFiles = fileList.filter(entry => entry.type.includes("image"));
            let _temp_file_list = [];
            // if (videoFiles.length > 0) {
            //     _temp_file_list = videoFiles;
            // } else if (imageFiles.length > 0) {
            //     _temp_file_list = imageFiles;
            // }
            if (imageFiles.length > 0) {
                _temp_file_list = imageFiles;
            } else if (videoFiles.length > 0) {
                _temp_file_list = videoFiles;
            }

            const cvat = getCore();

            const processMedia = async (finp, settingList) => {
                var r = await cvat.videoProcessing.cropPreviewProcessorList(finp, settingList);

                return new Promise((resolve, reject) => {
                    if (r) resolve(r);
                    else reject('Oopsy...');
                });
            }

            if (_temp_file_list.length > 0) {
                processMedia(_temp_file_list[0], settingList).then(
                    res => {
                        this.setState({
                            croppingPreviewListStarted: true,
                            croppingPreviewListStartedPrev: true,
                            croppingPreviewListReady: true,
                            croppingPreviewListSrcDict: res
                        });
                    }).catch( res => {
                        this.setState({
                            croppingPreviewListStarted: true,
                            croppingPreviewListStartedPrev: true,
                            croppingPreviewListReady: true,
                            croppingPreviewListSrcDict: res
                        });
                        notification.error({
                            message: 'Failed to generate preview',
                            description: `Please check video file(s) or contact admin. Re-load the page (F5). Thank you.`,
                        });
                    });
            }
        }
    }

    if (files.local.length > 0 && !this.state.croppingPreviewListStarted) {
        // compute hash of object for checking if anything has changed
        const fileh = hash(files);

        this.setState(
            (prevState, props) => {
                return {
                    croppingPreviewListStarted: true,
                    croppingPreviewListStartedPrev: prevState.croppingPreviewListStarted,
                    fileHash: fileh,
                    fileHashPrev: prevState.fileHash
                }
            }, function () {processCropping();});
    }
};

    private generateCroppingPreview(tag: string, x1rel: number, y1rel: number, x2rel: number, y2rel: number) {
        console.log('THIS IS generateCroppingPreview');
        const {files, croppingPreviews} = this.state;
        const { croppingPreviewStarted, croppingPreviewReady, croppingPreviewSrc } = croppingPreviews[tag]

        console.log(files);
        console.log("files.local.length > 0");
        console.log(files['local'].length);

        if (files.local.length > 0 && !croppingPreviewStarted) {
            const filesTemp = files;
            const fileKeys = Object.keys(filesTemp);

            var stateCroppingStarted = {};
            stateCroppingStarted[tag] = {
                croppingPreviewStarted: true,
                croppingPreviewReady: croppingPreviewReady,
                croppingPreviewSrc: croppingPreviewSrc,
            };
            console.log(stateCroppingStarted);
            this.setState({croppingPreviews: {...this.state.croppingPreviews, ...stateCroppingStarted}});

            if (fileKeys.includes('local')) {
                const fileList = filesTemp['local'];
                const videoFiles = fileList.filter(entry => entry.type.includes("video"));
                const imageFiles = fileList.filter(entry => entry.type.includes("image"));

                console.log("videoFiles");
                console.log(videoFiles);

                console.log("imageFiles");
                console.log(imageFiles);

                if (imageFiles.length > 0) {
                    const cvat = getCore();

                    async function processMedia(finp, x1rel, y1rel, x2rel, y2rel) {
                        var r = await cvat.videoProcessing.cropPreviewProcessor(finp, x1rel, y1rel, x2rel, y2rel);
                        // this.setState({croppingPreviewReady: true, croppingPreviewSrc: r});

                        //console.log(r);
                        console.log("async function processMedia");
                        // const file_ret = await cvat.videoProcessing.cropMedia(finp, 80, 60, 400, 800);
                        // return file_ret;

                        return new Promise((resolve, reject) => {
                            if (r) resolve(r);
                            else reject('Oopsy...');
                        });
                    }

                    processMedia(imageFiles[0], x1rel, y1rel, x2rel, y2rel).then(
                        res => {
                            console.log('resolve promise');
                            //console.log(res);
                            var stateCroppingFinished = {};
                            stateCroppingFinished[tag] = {
                                croppingPreviewStarted: true,
                                croppingPreviewStartedPrev: true,
                                croppingPreviewReady: true,
                                croppingPreviewSrc: res};
                            console.log(stateCroppingFinished);
                            this.setState({croppingPreviews: {
                                ...this.state.croppingPreviews, ...stateCroppingFinished}});
                        });
                } else if (videoFiles.length > 0) {
                    const cvat = getCore();

                    async function processMedia(finp, x1rel, y1rel, x2rel, y2rel) {
                        var r = await cvat.videoProcessing.cropPreviewProcessor(finp, x1rel, y1rel, x2rel, y2rel);
                        // this.setState({croppingPreviewReady: true, croppingPreviewSrc: r});

                        //console.log(r);
                        console.log("async function processMedia");
                        // const file_ret = await cvat.videoProcessing.cropMedia(finp, 80, 60, 400, 800);
                        // return file_ret;

                        return new Promise((resolve, reject) => {
                            if (r) resolve(r);
                            else reject('Oopsy...');
                        });
                    }

                    processMedia(videoFiles[0], x1rel, y1rel, x2rel, y2rel).then(
                        res => {
                            console.log('resolve promise');
                            //console.log(res);
                            var stateCroppingFinished = {};
                            stateCroppingFinished[tag] = {
                                croppingPreviewStarted: true,
                                croppingPreviewStartedPrev: true,
                                croppingPreviewReady: true,
                                croppingPreviewSrc: res};
                            console.log(stateCroppingFinished);
                            this.setState({croppingPreviews: {
                                ...this.state.croppingPreviews, ...stateCroppingFinished}});
                        });
                }
            }
        }
    };

    private viewCroppingPreviewList(settingList: any []): JSX.Element {
        // tag: string, x1rel: number, y1rel: number, x2rel: number, y2rel: number
        const {files, croppingPreviewListStarted, croppingPreviewListReady, croppingPreviewListSrcDict,
        croppingSelection, croppingFilesExceedSize} = this.state;

        if (!croppingFilesExceedSize) {
            this.generateCroppingPreviewList(settingList);
        }//.then(() => this.setState({promiseFulfilled: true}))
        else {
            const fileh = hash(files);
            const handleFileSizeExceed = () => {
                if (this.state.fileHashPreSubmit !== this.state.fileHashPrevPreSubmit) {
                    this.croppingConfigurationComponent.current.setVal('No cropping');
                    this.setState({croppingSelection: 'No cropping'});
                    this.privacySettingsNotAvialable('One or multiple file(s) exceed file size limit (2 GB) for cropping function. Only proceed with file upload if data does not contain any critical information. Cropping Video files larger than 2GB is only supported in Firefox. Consider switching the browser and upload these file(s) there.')
                }
            }
            this.setState(
            (prevState, props) => {
                return {
                    fileHashPreSubmit: fileh,
                    fileHashPrevPreSubmit: prevState.fileHashPreSubmit,
                    fileHash: '',
                    fileHashPrev: '',
                }
            }, function () {handleFileSizeExceed();});
        }

        //if (croppingPreviewListReady) {
        if (croppingPreviewListReady) {

            const croppingSettingSelected = settingList.filter(
                (settingItem) => settingItem['tag'] == croppingSelection
            );

            const items = croppingSettingSelected.map(settingItem => {return (<>
                {/*<Col span={12}>*/}
                {/*    {settingItem['tag']}*/}
                {/*</Col>*/}
                <Row>
                    <Col span={12}>Preview</Col>
                    <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {
                            croppingPreviewListSrcDict[settingItem['tag']] === null ? (
                                <Skeleton.Image size={128} />
                            ) : (
                                <img src={croppingPreviewListSrcDict[settingItem['tag']]}/>
                            )
                        }
                    </Col>
                </Row>
            </>)});
            return items;

        } else if (croppingPreviewListStarted) {
            const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
            const items = settingList.map(settingItem => {return (
                <p>Cropping for {settingItem['tag']}</p>
            )});
            return (
              <Spin indicator={antIcon} tip="Generating Preview">
                  <Row>
                  <Col span={12}>Preview</Col>
                  <Col span={12} style={{textAlign: 'right'}}>
                  {/*<Col span={12} style={{align: 'right', display: 'flex', justifyContent: 'flex-end', justifyItems: 'right'}}>*/}
                      <Skeleton.Image size={128} />
                  </Col>
                  {/*{items}*/}
                  </Row>
              </Spin>
            );
        } else {
            const items = settingList.map(settingItem => {return (<p>{settingItem['tag']}</p>)});
            console.log(items);
            //return items;
            //return (<p>Please select data for the upload first - preview images are generated thereafter.</p>);
            return (<>
                    <Row>
                    <Col span={12} style={{textAlign: 'left'}}>Select data for upload first.</Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                        <Skeleton.Image size={128}/>
                    </Col>
                    </Row>
                </>
            )
        }
    }

    private viewCroppingPreview(tag: string, x1rel: number, y1rel: number, x2rel: number, y2rel: number): JSX.Element {
        const { files, croppingPreviews } = this.state;
        // console.log(croppingPreviews);
        const {croppingPreviewStarted, croppingPreviewReady, croppingPreviewSrc} = croppingPreviews[tag];

        this.generateCroppingPreview(tag, x1rel, y1rel, x2rel, y2rel);//.then(() => this.setState({promiseFulfilled: true}))

        if (croppingPreviewReady) {
            return (
                <>
                    <Col span={12}>
                        Option {tag}
                    </Col>
                    <Col span={12}>
                        <img src={croppingPreviewSrc}/>
                    </Col>
                </>
        );
        } else {
            return (<p>viewCroppingPreview: Option {tag}</p>);
        }
    }

    private handleResetCroppingOptions = (): void => {
        this.croppingConfigurationComponent.current.resetFields();
    };

    private renderCroppingOptionsBlock(disabled = false, optDict: any[]): JSX.Element {
        const { croppingPreviewListReady } = this.state

        let opt_dict;
        if (optDict){
            opt_dict = optDict;
        } else {
            opt_dict = ['No cropping', 'EPK-i7010', 'EPK-i', 'EPK-i5500c'];
        }

        return (
            <Row>
                <Col span={24}>
                    <CroppingConfigurationForm
                        ref={this.croppingConfigurationComponent}
                        onSubmit={this.handleSubmitCroppingConfiguration}
                        handleChange={(value) => {
                            this.setState({
                                croppingSelection: value,
                            });
                        }}
                        options={opt_dict.map((key) => (<Option value={key}>{key}</Option>))}
                        disabled={!croppingPreviewListReady || disabled}
                        onReset={this.handleResetCroppingOptions}
                    />
                </Col>
            </Row>
        );
    }

    private renderCroppingBlock(rendAdBlock = true, disabled = false, croppingDefault:string): JSX.Element {
        const {installedGit} = this.props;
        const {activeFileManagerTab, files, croppingPreviewReady, croppingFilesExceedSize} = this.state;
        //
        // const onLoaded = (cv) => {
        //     console.log('opencv loaded, cv');
        // }
        //
        // function MyComponent() {
        //     const data = useOpenCv()
        //     console.log(data)
        //     return (<p>OpenCv React test</p>)
        // }
        //
        // // {false && <OpenCvProvider onLoad={onLoaded} openCvPath='/opencv/opencv.js'>}
        // // {false && </OpenCvProvider>}
        //
        // var saveData = (function () {
        //     var a = document.createElement("a");
        //     document.body.appendChild(a);
        //     a.style = "display: none";
        //     return function (blob, fileName) {
        //         const url = window.URL.createObjectURL(blob);
        //         console.log(url);
        //         a.href = url;
        //         a.download = fileName;
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     };
        // }());
        //
        // function OpenCvTest(files) {
        //     console.log("OpenCvTest");
        //     //console.log(files);
        //     //console.log(files.files);
        //
        //     const filesTemp = files.files;
        //
        //
        //     if (true) {
        //         const fileKeys = Object.keys(filesTemp);
        //         const data = useOpenCv();
        //         const cv = data.cv;
        //         console.log("cv");
        //         console.log(cv);
        //
        //
        //         if (fileKeys.includes('local')) {
        //             const fileList = filesTemp['local'];
        //             console.log("fileList");
        //             console.log(fileList);
        //             const videoFiles = fileList.filter(entry => entry.type.includes("video"));
        //             console.log("videoFiles");
        //             console.log(videoFiles);
        //             if (videoFiles.length > 0) {
        //
        //
        //                 const cvat = getCore();
        //
        //                 async function processMedia(finp) {
        //                     var r = await cvat.videoProcessing.cropPreview(finp, 800, 600, 20, 30);
        //                     //this.setState({croppingPreviewReady: true});
        //                     console.log(r);
        //                     // const file_ret = await cvat.videoProcessing.cropMedia(finp, 80, 60, 400, 800);
        //                     // return file_ret;
        //                     return r;
        //                 }
        //
        //                 const file_ret = processMedia(videoFiles[0]);
        //                 if (croppingPreviewReady){
        //                     return (<img src={file_ret} height="200"/>);
        //                 } else {
        //                     return (<p>Placehoslder for preview.</p>);
        //                 }
        //                 return file_ret;
        //
        //                 // return (<img src={file_ret} height="200"/>);
        //                 console.log(fileList);
        //
        //                 console.log(file_ret);
        //                 console.log(file_ret);
        //
        //                 console.log("video identified");
        //
        //                 // FFMPEG.process(videoFiles[0],
        //                 //     //'-filter_complex "[0:v]crop=200:200:300:100[cropped]" -map "[cropped]" -c:a copy',
        //                 //     // '-metadata location="" -metadata location-eng="" -metadata author="" -c:v copy -c:a copy',
        //                 //     '-filter:v "crop=80:60:200:100"',
        //                 //     function (e) {
        //                 //         const video = e.result;
        //                 //         console.log(video);
        //                 //         saveData(video, 'ddd.mp4');
        //                 //         // var csvURL = window.URL.createObjectURL(video);
        //                 //         // console.log(csvURL);
        //                 //         // const tempLink = document.createElement(video.name);
        //                 //         // tempLink.href = csvURL;
        //                 //         // tempLink.setAttribute('download', video.name);
        //                 //         // tempLink.click();
        //                 //         // console.log(tempLink);
        //                 //     }.bind(this));
        //
        //                 // let mat = cv.imread(videoFiles[0]);
        //                 // console.log(mat.shape);
        //                 //cv.imageSizes('canvasOutput', mat);
        //                 // mat.delete();
        //
        //                 // var video = document.createElement('video_cv');
        //                 // video.src = videoFiles[0];
        //                 // video.autoplay = false;
        //                 //
        //                 // let src = new cv.Mat(100, 200, cv.CV_8UC4);
        //                 // let dst = new cv.Mat(100, 200, cv.CV_8UC1);
        //                 // let cap = cv.VideoCapture(video);
        //                 // let frames = cap.get(CV_CAP_PROP_FRAME_COUNT);
        //                 // cap.set(CV_CAP_PROP_POS_FRAMES, 5);
        //                 // console.log("total frames");
        //                 // console.log(frames);
        //             }
        //         }
        //     }
        //     return (<p>OpenCv processed</p>)
        // };
                            //         {false && (
                            // <Col span={12}>
                            //     Here comes the options form for cropping.
                            // </Col>
                            // <Col span={12}>
                            //     {false && <MyComponent />}
                            //     {false && <OpenCvTest files={files} />}
                            //     Preview field comes here.
                            //     {this.viewCroppingPreview('first', 200,300, 50,20)}
                            //     {this.viewCroppingPreview('second',30,50, 200,300)}
                            // </Col>
                            //     )}
        // [{tag: 'No cropping', x1rel: 0, y1rel: 0, x2rel: 0, y2rel: 0},
        //                    {tag: 'EPK-i7010', x1rel: 0.02240, y1rel: 0.05185, x2rel: 0.66073, y2rel: 0.95000},
        //                    {tag: 'EPK-i', x1rel: 0.01777, y1rel: 0.00495, x2rel: 0.97012, y2rel: 0.99740},
        //                    {tag: 'EPK-i5500c', x1rel: 0.16656, y1rel: 0.01944, x2rel: 0.83229, y2rel: 0.90666},
        //                ]

        const settingList = [{tag: 'No cropping', x1rel: 0, y1rel: 0, x2rel: 0, y2rel: 0},
                            {tag: 'EPK-i7010', x1rel: 0.02240, y1rel: 0.05185, x2rel: 0.66073, y2rel: 0.95000},
                            {tag: 'EPK-i', x1rel: 0.01777, y1rel: 0.00495, x2rel: 0.97012, y2rel: 0.99740},
                            {tag: 'EPK-i5500c', x1rel: 0.16656, y1rel: 0.01944, x2rel: 0.83229, y2rel: 0.90666},
                        ]

        // const defaultSetting = settingList.filter(entry => entry.tag === croppingDefault);
        // let settingListReOrder = null;
        //
        // if (false) { //(defaultSetting.length == 1) {
        //     settingListReOrder = [
        //         settingList.find(item => item.tag === croppingDefault),
        //         ...settingList.filter(item => item.tag !== croppingDefault),
        //     ];
        // } else {
        //     settingListReOrder = settingList;
        // }
        // console.log("croppingDefault");
        // console.log(croppingDefault);
        // console.log("settingListReOrder");
        // console.log(settingListReOrder);

        const optDict = settingList.map( item => item.tag );

        if (rendAdBlock) {
            return (
                <>
                    <Col span={24}>
                        <Text className='cvat-text-color'>Data privacy settings</Text>
                    </Col>
                    <Col span={24}>
                        {/*<Collapse>*/}
                        {/*    <Collapse.Panel key='1' header={<Text className='cvat-title'>Media cropping</Text>}>*/}
                        {this.renderCroppingOptionsBlock(disabled || croppingFilesExceedSize, optDict)}
                        {this.viewCroppingPreviewList(settingList)}
                        {false && this.viewCroppingPreview('first', 0.16656, 0.05648, 0.83229, 0.94370)}
                        {false && this.viewCroppingPreview('second', 0.02240, 0.05185, 0.66073, 0.95000)}
                        {false && this.viewCroppingPreview('second', 0.01777, 0.00495, 0.97012, 0.99740)}
                        {false && this.viewCroppingPreview('No cropping', 0, 0, 0, 0)}
                        {false && (<Button type='primary' onClick={this.handleCroppingClick}>
                            Cropping
                        </Button>)}
                        {/*    </Collapse.Panel>*/}
                        {/*</Collapse>*/}
                    </Col>
                </>
            );
        } else {
            return (
                <Col span={24}>
                </Col>
            );
        }
    }

    public render(): JSX.Element {
        const { status, upload_progress, croppingEnabled, croppingDefault } = this.props;
        const { state_current_task_create, state_total_tasks, croppingPreviewListReady,
            croppingPreviewListStarted } = this.state;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED';
        const uploading = upload_progress > 0;
        const task_prog = state_current_task_create >= 0;

        console.log('uploading, upload_progress');
        console.log(uploading);
        console.log(upload_progress);
        console.log('task_current');
        console.log(state_current_task_create);
        console.log(state_total_tasks);


        return (
            <Row justify='start' align='middle' className='cvat-create-patients-content'>
                <Col span={24}>
                    <Text className='cvat-title'>Basic configuration</Text>
                </Col>
                {this.renderBasicBlock()}
                {this.renderProjectBlock()}
                {this.renderSubsetBlock()}
                {this.renderFileRequirementBlock()}
                {this.renderFilesBlock(true)}
                {this.renderAdvancedBlock(false)}
                {croppingEnabled && this.sharedArrayBufferAvailable && this.renderCroppingBlock(true, loading,
                    croppingDefault)}
                <Col span={24}>{task_prog ? <Alert type="warning"
                                                message={"Upload in progress"}
                                                description={`Please do not leave the current page or close the browser window.
                                                These actions cancel the upload.`}
                /> : null }</Col>
                <Col span={14}>{task_prog ? <Alert message={`Creating task ${state_current_task_create}/${state_total_tasks}`} /> : null }</Col>
                <Col span={4}>{task_prog ? <Progress type="line"
                                                      showInfo={false}
                                                      percent={Math.floor(state_current_task_create / state_total_tasks * 100)}
                /> : null}</Col>
                <Col span={6} />
                <Col span={14}>{loading ? <Alert message={status} /> : null}</Col>
                <Col span={4}>{uploading ? <Progress type="line" percent={upload_progress} /> : null}</Col>
                <Col span={6} className='cvat-create-patients-submit-section'>
                    <Button loading={loading} disabled={loading || (!croppingPreviewListReady && croppingPreviewListStarted)} type='primary' onClick={this.handleSubmitClick}>
                        Upload
                    </Button>
                    {false && (
                    <Button type='primary' onClick={this.validateFilesClick}>
                        Validate Files
                    </Button>)}
                </Col>
            </Row>
        );
    }
}

export default withRouter(CreatePatientsContent);
